.LatestProductsSection--side {
  display: flex;
  flex-direction: column;

  div:first-child {
    border-top: none;
  }

  .LatestProductsSection--side-cta {
    margin-top: auto;
  }
}

.LatestProductsSection--side-title {
  display: flex;
  align-items: center;
  font-family: $font-family-secondary;
  @include font-3-tight;
  @include themed() {
    color: theme($secondary);
  }
}