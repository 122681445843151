.EstimatedReadTime {
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 15px;

    path {
      @include themed() {
        fill: theme($text-light-accent);
      }
    }
  }

  span {
    margin-left: 5px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-normal;
    @include font-2;
    @include themed() {
      color: theme($text-dark-accent);
    }
  }
}
