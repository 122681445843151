.FooterNavigation {
  @include font-6;
  @include themed() {
    color: theme($text-light);
  }

  @include media-breakpoint-up(md) {
    @include font-4-tight;
    margin-bottom: 18px;
  }
}

.FooterNavigation-Title {
  opacity: 0.5;
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  margin-bottom: 22px;
}

.FooterNavigation-List {
  column-count: 2;
}

.FooterNavigation-List-Item-Link {
  text-decoration: none;
  font-weight: $font-weight-normal;
  @include themed() {
    color: theme($text-light);
  }

  &:hover {
    @include themed() {
      color: theme($text-light-hover);
    }
  }
}