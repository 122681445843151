.PostDetailShareArticle {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  p {
    font-family: $font-family-secondary;
    @include font-3-tight;
    @include themed() {
      color: theme($secondary)
    }

    span {
      @include themed() {
        color: theme($primary)
      }
    }
  }
}

.PostDetailShareArticle--Icons {
  min-width: 184px;

  .IconLink {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}