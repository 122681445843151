// COLORS
// color name generator
// https://chir.ag/projects/name-that-color/
$roti: #CBA145;
$tahiti-gold: #DD8808;
$porcelain: #F3F6F7;
$geyser: #CFD9E1;
$heather: #B7C5D0;
$regent-gray: #85949F;
$bermuda-gray: #6c8aa3;
$smalt-blue: #577A95;
$indigo: #4663C9;
$indigo-er: #5579f6;
$robins-blue: #0ABECA;
$robins-bluish: #079aa4;
$chathams-blue: #0F4168;
$elephant: #0C283F;

// FONT
$font-family-primary: 'Roboto', sans-serif;
$font-family-secondary: 'Roboto Slab', serif;
$font-weight-bold: 700;
$font-weight-semibold: 500;
$font-weight-normal: 400;
$base_page_width: 1160px;

// https://getbootstrap.com/docs/5.0/layout/breakpoints/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


$z-index-0: 0;
$z-index-1: 5;
$z-index-2: 10;
$z-index-3: 15;
$z-index-4: 20;
$z-index-5: 25;