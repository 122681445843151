.PostContentExcerpt {
  display: flex;
  align-items: center;
  flex-direction: column;

  .more-link {
    display:none;
  }
}

.PostContentExcerpt-Text {
  text-align: center;
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  @include font-8-loose;
  @include themed() {
    color: theme($text-dark);
  }
}

.PostContentExcerpt--divider {
  max-width: 100%;
  overflow: hidden;

  svg {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}