.MiniNumberedPost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include font-4-tight;
}

.MiniNumberedPost--Title {
  text-decoration: none;
  font-weight: $font-weight-normal;
  @include themed() {
    color: theme($text-dark)
  }
}
