.IconLink {
  svg {
    width: 14px;
    height: 14px;
  }

  &--WhiteFill path {
    fill: $white;
  }

  &--BlackFill path {
    fill: $black;
  }

  &--SecondaryFill path {
    @include themed() {
      fill: theme($secondary)
    }
  }

  &--PrimaryFill path {
    @include themed() {
      fill: theme($primary)
    }
  }

  &--WhiteStroke path {
    stroke: $white;
  }

  &--BlackStroke path {
    stroke: $black;
  }

  &--SecondaryStroke path {
    @include themed() {
      stroke: theme($secondary)
    }
  }

  &--PrimaryStroke path {
    @include themed() {
      stroke: theme($primary)
    }
  }

  &--PrimaryBorder {
    @include themed() {
      border: theme($primary)
    }

    &:hover {
      @include themed() {
        background-color: theme($primary);
      }

      path {
        fill: $white;
      }
    }
  }

  &--SecondaryBorder {
    @include themed() {
      border: theme($secondary)
    }

    &:hover {
      @include themed() {
        background-color: theme($secondary);
      }

      path {
        fill: $white;
        stroke: $white;
      }
    }
  }

  &--WhiteBorder {
    border-color: $white;
  }

  &--BlackBorder {
    border-color: $black;
  }

  &--BorderNone {
    border: none !important;
  }
}

.IconLink[class*='Button--'] {
  @extend %button_badge-def;
  font-size: $font-size-4;

  svg {
    padding: 0.75rem;
  }
}