.PopularPostsFilter--item {
  display: inline;
  font-weight: $font-weight-normal;
  cursor: pointer;
  @include font-4-tight;
  @include themed() {
    color: theme($text-dark-hover);
  }

  &:hover {
    @include themed() {
      color: theme($text-dark);
    }
  }
}

.PopularPostsFilter--active {
  @include themed() {
    color: theme($primary);
  }

  &:hover {
    @include themed() {
      color: theme($primary-hover);
    }
  }
}