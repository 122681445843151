.PostPage--LanguageVariation {
  text-align: center;
  @include themed() {
    background-color: theme($background-light-accent);
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-normal;
    @include themed() {
      color: theme($black-color);
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 4px;
    }
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    @include themed() {
      color: theme($primary);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
}

.PostPage--LatestProducts {
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.PostPage--AuthorAndFollow-Float {
  max-width: 170px;
  margin-left: auto;
  @include themed() {
    background-color: theme($background-light-accent);
  }

  .PostDetailAuthorName,
  .PostDetailAuthorSocials {
    text-align: center;
  }

  .PostDetailAuthorName {
    flex-direction: column;
    position: relative;
    padding-top: 44px;
    margin-top: 36px;

    .AvatarCircle {
      margin-right: 0 !important;
      position: absolute;
      top: -36px;
    }
  }

  .PostDetailAuthorSocials {
    flex-direction: column-reverse;
  }

  .PostDetailAuthorSocials--Icons .IconLink:last-child {
    margin-right: 0 !important;
  }

  .PostDetailAuthorSocials--Button {
    @extend .mb-3;
  }

  .PostDetailAuthorName--AuthorName-Author {
    @extend .pr-2;
    @extend .pl-2;
  }
}

.PostPage--MiddleColumn, .PostPage--RightColumn {
  position: relative;
}

.PostPage--RightColumn, .PostPage--LeftColumn {
  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.PostPage--PinnedPost {
  position: absolute;
  @include themed() {
    background-color: theme($background-light-accent);
  }

  figcaption {
    @extend .mx-5;
  }

  .CardPost--action {
    @extend .mx-5;
    @extend .mb-5;
  }
}

.PostPage--PinnedProduct {
  position: absolute;
}

.PostPage--ThinWidth-Centered {
  @extend %thin_width-centered;
}

.PostPage--MediumWidth-Centered {
  @extend %medium_width-centered;
}