.LeftAlignedPageBanner {
  font-weight: $font-weight-normal;
  @include themed() {
    background-color: theme($background-dark);
    color: theme($text-light);
  }

  > div {
    @include media-breakpoint-up(md) {
      background-image: url("/assets/img/top-decoration.svg");
      background-position: right top;
      background-repeat: no-repeat;
    }
  }
}

.LeftAlignedPageBanner--Caption {
  font-family: $font-family-secondary;
  @include themed() {
    color: theme($primary);
  }
}

.LeftAlignedPageBanner--Title {
  @include font-13;
}

.LeftAlignedPageBanner--Description {
  @include font-5;
  font-weight: $font-weight-normal;
  @include themed() {
    color: theme($text-light-accent);
  }
}