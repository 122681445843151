.Help-Ukraine {
  @include themed() {
    background-color: rgba(theme($primary), 0.05);
  }
}

.Help-Ukraine-link {
  padding: 7px 12px;
  border-radius: 15px;
  color: $white;
  font-weight: $font-weight-normal;
  font-size: $font-size-4;
  text-decoration: none;
  background-color: #354D95;


  &:hover {
    background-color: #304586;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    @include font-default;
    padding: 0;
    text-decoration: none;
    font-family: $font-family-primary;
    font-weight: $font-weight-normal;
    font-size: $font-size-4;
    background-color: transparent;
    @include themed() {
      color: theme($text-dark);
    }

    &:hover {
      background-color: transparent;
      @include themed() {
        color: theme($text-dark-hover);
      }
    }
  }
  justify-content: center;
  align-items: center;

  img {
    @include media-breakpoint-down(md) {
      width: 21px;
      height: 14px;
    }
    width: 16px;
    height: 10px;
  }
}
