.PostDetailAuthorName {
  display: flex;
  align-items: center;
}

.PostDetailAuthorName--AuthorName-By {
  font-weight: $font-weight-normal;
  @include font-1;
  @include themed() {
    color: theme($text-dark-accent)
  }
}

.PostDetailAuthorName--AuthorName-Author {
  @include font-6-tight;
  @include themed() {
    color: theme($text-dark)
  }

  a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: none;
  }
}