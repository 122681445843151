.ContentSubscribeForm {
  border-radius: 3px;
  @extend %medium_width-centered;

  .MailchimpForm--field input {
    border: 1px solid;
    @include themed() {
      border-color: theme($border-dark);
    }
  }
}

.ContentSubscribeForm--light {
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.ContentSubscribeForm--content-center {
  max-width: 572px;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(md) {
    margin: 0 3rem;
  }
}

.ContentSubscribeForm--image-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContentSubscribeForm--caption.ContentSubscribeForm--caption {
  font-family: $font-family-secondary;
  margin: 0 0 1.25rem 0;
  @include font-2;
  @include media-breakpoint-up(md) {
    @include font-4-tight;
  }
  @include themed() {
    color: theme($primary);
  }
}

.ContentSubscribeForm--hook.ContentSubscribeForm--hook {
  max-width: 28ch;
  margin: 0 0 1rem 0;
  font-weight: $font-weight-semibold;
  @include font-8-tight;
  @include themed() {
    color: theme($text-dark);
  }

  span {
    @include themed() {
      color: theme($primary);
    }
  }
}
