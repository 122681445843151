.ContentPost.ContentPost {
  border-radius: 3px;
  box-sizing: border-box;
  @extend %medium_width-centered;
  @include themed() {
    background-color: theme($background-light-accent);
  }

  .Author {
    text-decoration: none;
    @include themed() {
      color: theme($text-dark-accent);
    }
  }
}

.ContentPost--figure.ContentPost--figure {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  align-items: center;

  @include media-breakpoint-up(md) {
    grid-template-columns: 200px 1fr;
  }
}

.ContentPost--figure-ImgWrapper.ContentPost--figure-ImgWrapper {
  @extend %image_wrapper-def;
}

.ContentPost--figure-Img.ContentPost--figure-Img {
  @extend %image-def;
  aspect-ratio: 16 / 9;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.ContentPost--title.ContentPost--title {
  margin: 0.5rem 0 0.75rem 0;
  font-family: $font-family-primary;
  @include font-8-tight;
  @include themed() {
    color: theme($text-dark);
  }
}

.ContentPost--cta.ContentPost--cta {
  font-weight: $font-weight-normal;
  @include font-4-tight;
  @include themed() {
    color: theme($primary);
  }
}