.ProductDetailAuthorName {
  display: flex;
  align-items: center;
}

.ProductDetailAuthorName--AuthorName-Job {
  font-weight: $font-weight-normal;
  @include font-1;
  @include themed() {
    color: theme($text-dark-accent);
  }
}

.ProductDetailAuthorName--AuthorName-Author {
  @include font-5-tight;
  @include themed() {
    color: theme($text-dark);
  }
}