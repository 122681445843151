.ProductPromotional {
  text-align: center;
  border-radius: 3px;
  @include themed() {
    color: theme($text-light);
    background-color: theme($background-dark);
  }

  .Divider--text {
    font-family: $font-family-secondary;
    @include font-2;
    @include themed() {
      color: theme($border-light);
    }

    span {
      @include themed() {
        color: rgba(theme($text-light), 0.8);
      }
    }
  }
}

.ProductPromotional--caption {
  @include font-4;
  font-family: $font-family-secondary;
}

.ProductPromotional--title {
  @include font-6-tight;

  &-large {
    @include media-breakpoint-up(lg) {
      @include font-9-tight;
    }
  }
}

.ProductPromotional--subTitle {
  @include font-3-tight;
}

.ProductPromotional--caption, .ProductPromotional--subTitle {
  font-weight: $font-weight-normal;
  @include themed() {
    color: theme($text-light-accent);
  }
}

.ProductPromotional--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ProductPromotional--image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductPromotional--image {
  max-width: 150px;
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.ProductPromotional--image-decoration {
  @include media-breakpoint-down(md) {
    .ProductPromotional--image {
      max-width: 100px;
    }
  }
  @include media-breakpoint-up(md) {
    background-image: url("/assets/img/productPromotional-decoration.png");
    background-position: left center;
    background-repeat: no-repeat;
    .ProductPromotional--image {
      max-width: 210px;
    }
  }
}

.ProductPromotional--quote, .ProductPromotional--quotee {
  @include font-3;
}

.ProductPromotional--quote {
  font-family: $font-family-secondary;
  @include themed() {
    color: theme($text-light-accent);
  }
}

.ProductPromotional--quotee span {
  @include themed() {
    color: theme($primary);
  }
}