.Divider {
  border-bottom: 1px solid;
}

.Divider--text {
  display: flex;
  align-items: center;
  text-align: center;
}

.Divider--text::before,
.Divider--text::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid;
}

.Divider--text:not(:empty)::before {
  margin-right: .5em;
}

.Divider--text:not(:empty)::after {
  margin-left: .5em;
}

.Divider--dashed {
  border-style: dashed;
}

.Divider--light {
  @include themed() {
    border-color: theme($border-light)
  }
}

.Divider--dark {
  @include themed() {
    border-color: theme($border-dark);
  }
}

.Divider--secondary {
  @include themed() {
    border-color: theme($secondary);
  }
}

.Divider--thick {
  border-bottom-width: 3px;
}

.Divider--short {
  min-width: 111px;
  max-width: 111px;
}