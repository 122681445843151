.PaymentForm--form {
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.PaymentForm--card {
  @include themed() {
    background-color: theme($background-light);
  }
}