.PostListItem--highlight {
  min-height: 370px;
  border-top: 3px solid;
  border-radius: 3px 3px 0 0;
  @include themed() {
    background-color: theme($background-light-accent)
  }

  .PostListItem--figure > a {
    border-radius: 0;

    img {
      border-radius: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    border-top: none;
  }

  & figcaption {
    box-sizing: border-box;
    border-radius: 0 3px 0 0;

    @include media-breakpoint-up(lg) {
      border-top: 3px solid;
    }
  }
}

[class*="PostListItem--highlight-partner"] {
  @include themed() {
    border-top-color: theme($special-one)
  }

  & .CapitalCta {
    @include themed() {
      color: theme($special-one)
    }
  }

  @include media-breakpoint-up(lg) {
    & figcaption {
      @include themed() {
        border-top-color: theme($special-one)
      }
    }
  }
}

[class*="PostListItem--highlight-opinion"] {
  @include themed() {
    border-top-color: theme($tertiary)
  }

  & .CapitalCta {
    @include themed() {
      color: theme($tertiary)
    }
  }

  @include media-breakpoint-up(lg) {
    & figcaption {
      @include themed() {
        border-top-color: theme($tertiary)
      }
    }
  }
}

.PostListItem--figure {
  height: 100%;
  grid-gap: 0;
  grid-template-rows: max-content;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}

.PostListItem--figure-ImgWrapper {
  @extend %image_wrapper-def;
  max-width: 100%;
}

.PostListItem--figure-Img {
  @extend %image-def;
  aspect-ratio: 16 / 9;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.PostListItem--figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    min-height: 200px;
  }
}

.PostListItem--figcaption-Title {
  @include font-6-tight;

  @include media-breakpoint-up(lg) {
    @include font-8-tight;
  }
}

.PostListItem--figcaption-TitleLink {
  text-decoration: none;
  @include themed() {
    color: theme($text-dark)
  }

  &:hover {
    @include themed() {
      color: theme($text-dark-accent)
    }
  }
}

.PostListItem--action {
  @extend %action_bar-def;
}
