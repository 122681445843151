%thin_width-centered {
  max-width: 572px;
  margin: 0 auto;
}

%medium_width-centered {
  max-width: 666px;
  margin: 0 auto;
}

%button_badge-def {
  line-height: $line-height-2;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}

%image-def {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

%image_wrapper-def {
  display: block;
  overflow: hidden;
  border-radius: 3px;
}

%action_bar-def {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  @include themed() {
    border-color: theme($border-dark);
  }
}

%action_bar-bordered-def {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid;
  @include themed() {
    border-color: theme($border-light);
  }
}
