.HeaderLine {
  .Layout--left {
    display: flex;
    align-items: center;
  }

  .Layout--right {
    align-self: baseline;
  }
}

.HeaderLine--logo svg {
  width: 114px;
  height: 38px;
  @include media-breakpoint-up(md) {
    width: 144px;
    height: 48px;
  }
}

.Headerline--hook {
  @include font-2-tight;
  text-align: center;

  @include themed() {
    color: rgba(theme($text-dark), 0.5);
  }
}

.HeaderLine--UserLogin {
  display: inline-flex;
  align-items: center;
  font-family: $font-family-secondary;
  text-decoration: none;
  @include font-3-tight;

  @include themed() {
    color: theme($text-dark);
  }

  &:hover {
    @include themed() {
      color: theme($text-dark-hover);
    }
  }

  svg {
    width: 13px;
    height: 14px;

    path {
      @include themed() {
        fill: theme($primary);
      }
    }
  }
}


.HeaderLine--PromoLink {
  display: inline-flex;
  align-items: end;
  font-family: $font-family-primary;
  font-weight: $font-weight-normal;
  font-size: $font-size-4;
  text-decoration: none;

  @include themed() {
    color: theme($text-dark);
  }

  &:hover {
    @include themed() {
      color: theme($text-dark-hover);
    }
  }
}