.AvatarCircle--image {
  border-radius: 50%;
  margin-right: 10px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.AvatarCircle--bordered {
  @include themed() {
    border: 5px solid theme($background-light)
  }
  margin: 0;
}
