.ErrorPage {
  text-align: center;

  @include themed() {
    color: theme($text-light);
    background-color: theme($background-dark);
  }

  > div {
    @include media-breakpoint-up(md) {
      background-image: url("/assets/img/top-decoration.svg"),
      url("/assets/img/bottom-decoration.svg");
      background-position: right top, left bottom;
      background-repeat: no-repeat, no-repeat;
    }
  }
}

.ErrorPage--wrapper {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    max-width: 650px;
  }
}

.ErrorPage--Caption {
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  @include themed() {
    color: theme($primary);
  }
}

.ErrorPage--Title {
  @include font-13;

  @include media-breakpoint-up(md) {
    @include font-14;
  }
}

.ErrorPage--Description {
  @include font-6;
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(md) {
    @include font-8-tight;
  }
}