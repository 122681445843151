.Footer {
  width: 100%;
  @include themed() {
    background: theme($background-dark);
  }
  @include media-breakpoint-up(xxl) {
    background-image: url("/assets/img/footer-decoration-bottom.svg"),
    url("/assets/img/footer-decoration-top.svg");
    background-position: left bottom, right calc(0% - 50px);
    background-repeat: no-repeat, no-repeat;
  }
}

.Footer-top--left {
  min-width: 100%;
}

.Footer--logo {
  svg {
    fill: $white;
    width: 126px;
    height: 42px;
  }
}

.Footer--newsletter {
  @include media-breakpoint-up(md) {
    .SubscribeToNewsletter--hook {
      @include font-7-tight;
    }
  }
}

.Footer-top--right {
  display: flex;
  line-height: $line-height-2;
  flex-direction: column;
  font-weight: $font-weight-normal;
}

.Footer--subtitle {
  @include font-4-tight;
  width: 75%;
  @include themed() {
    color: theme($text-light)
  }
}

.Footer--description {
  width: 100%;
  @include font-3;
  @include themed() {
    color: theme($text-light-accent)
  }
}

.Footer--link {
  font-weight: $font-weight-normal;
  @include font-4-tight;
  @include themed() {
    color: theme($primary)
  }

  &:hover {
    @include themed() {
      color: theme($primary-hover)
    }
  }
}

.Footer--copyright {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }

  a {
    font-weight: $font-weight-normal;
    text-decoration: none;
    @include font-2;
    @include themed() {
      color: theme($text-light)
    }

    &:hover {
      @include themed() {
        color: theme($text-light-hover)
      }
    }
  }

  p {
    font-weight: $font-weight-normal;
    @include font-2;
    display: inline-block;
    width: auto;
    @include themed() {
      color: theme($text-light-accent)
    }

    span {
      display: none;
      margin: 0 0 0 6px;
      @include media-breakpoint-up(md) {
        display: inline-block;
      }
      @include themed() {
        color: theme($primary)
      }
    }
  }
}

.Footer--Horizontal-Nav {
  white-space: nowrap;
  margin-left: 6px;

  @include media-breakpoint-up(md) {
    display: inline-block;
  }

  a:not(:first-child) {
    border-left: 1px solid;
    padding-left: 7px;
    margin-left: 7px;
    @include themed() {
      border-color: theme($border-light);
    }
  }
}
