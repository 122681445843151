%wordpress-content {
  em {
    font-style: italic;
  }

  strong {
    font-weight: $font-weight-bold;
  }

  .uppercase {
    text-transform: uppercase;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  code {
    font-family: $font-family-secondary;
    @include font-3;
  }

  .has-text-align-justify {
    text-align: justify;
  }

  ul {
    list-style: none;
    padding-bottom: 24px;
  }

  li {
    @include font-5;
    font-weight: $font-weight-normal;
    margin-left: 20px;
    position: relative;
    @include themed() {
      color: rgba(theme($text-dark), 0.8);
    }

    &::before {
      content: "+";

      @include themed() {
        color: theme($primary);
      }
      width: 1em;
      margin-right: 12px;
      position: absolute;
      left: -20px;

      &:hover {
        @include themed() {
          color: theme($primary-hover);
        }
      }
    }
  }

  ol {
    list-style: decimal;
    padding-top: 16px;
    padding-bottom: 24px;
    @include font-5;
    font-weight: $font-weight-normal;
    @include themed() {
      color: rgba(theme($text-dark), 0.8);
    }
    margin-left: 20px;
  }

  p {
    @include font-default;
    font-family: $font-family-primary;
    font-weight: $font-weight-normal;
    margin-bottom: 26px;
    padding: 0;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      @include font-5;
    }
    @include themed() {
      color: rgba(theme($text-dark), 0.8);
    }

    + ul,
    + .wp-block-buttons {
      margin-top: -20px;
    }
  }

  a {
    text-decoration: underline;
    @include themed() {
      color: theme($primary);
    }

    &:hover {
      @include themed() {
        color: theme($primary-hover);
      }
    }
  }

  h1 {
    font-family: $font-family-secondary;
    font-weight: $font-weight-semibold;
    margin-bottom: 26px;
    padding: 0;
    @include font-11-loose;
    @include themed() {
      color: theme($primary);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      @include font-12-loose;
    }
  }

  h2 {
    font-family: $font-family-secondary;
    font-weight: $font-weight-semibold;
    margin-bottom: 26px;
    padding: 0;
    @include font-11-loose;
    @include themed() {
      color: theme($primary);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      @include font-12-loose;
    }
  }

  .has-large-font-size {
    @include font-11-loose;
  }

  h3 {
    font-family: $font-family-secondary;
    font-weight: $font-weight-semibold;
    margin-bottom: 26px;
    padding: 0;
    @include font-7;
    @include themed() {
      color: rgba(theme($text-dark), 0.8);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      @include font-8-loose;
    }
  }

  h4 {
    font-family: $font-family-secondary;
    font-weight: $font-weight-semibold;
    @include font-6;
    margin-bottom: 26px;
    padding: 0;
    @include themed() {
      color: rgba(theme($text-dark), 0.8);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      @include font-7;
    }
  }

  .has-white-color {
    p {
      color: $white;
    }
  }

  .wp-block-separator {
    border: 0;
    height: 0;
    border-bottom: 3px solid;
    width: 111px;
    margin: 60px auto 24px;
    @include themed() {
      border-color: theme($primary);
    }

    &.is-style-wide {
      margin-left: 0;
      margin-right: 0;
      @extend .Divider;
      @extend .Divider--dashed;
      @extend .Divider--dark;
      width: 100%;
    }

    + p + .wp-block-separator {
      margin-top: 24px;
    }
  }

  .wp-block-quote, .wp-block-pullquote {
    margin-bottom: 17px;
    text-align: center;
    display: grid;

    blockquote {
      display: grid;
    }

    p {
      font-family: $font-family-secondary;
      font-weight: $font-weight-semibold;
      text-align: center;
      max-width: 100%;
      padding: 0;
      margin: 0;
      @include font-10;

      @include themed() {
        color: theme($primary);
      }

      @include media-breakpoint-up(md) {
        @include font-12-loose;
      }
    }

    cite {
      font-family: $font-family-primary;
      font-weight: $font-weight-normal;
      @include font-3;
      display: inline-block;
      margin: 0 auto;
      padding-top: 14px;

      @include themed() {
        color: theme($primary);
      }

      mark {
        @include themed() {
          color: rgba(theme($text-dark-accent), 0.8);
        }
      }
    }

    img {
      margin-top: 24px;
      border-radius: 50%;
      width: 50px !important;
      height: 50px;
    }

    + .wp-block-separator {
      margin-top: 0;
      margin-bottom: 60px;
    }

    + p {
      margin-top: 60px;
    }
  }

  .wp-block-image,
  .wp-block-media-text__media {
    margin-bottom: 14px;

    > img,
    > figure:not(.is-resized) > img,
    > a:not(.is-resized) > img,
    > figure > a > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: top;
    }

    &.is-style-rounded {
      img {
        border-radius: 50%;
      }
    }
  }

  .aligncenter,
  .is-content-justification-center,
  .has-text-align-center {
    text-align: center;
  }

  .wp-block-gallery {
    margin-bottom: 50px;

    img {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .blocks-gallery-item {
    figure {
      height: 100%;
    }

    img {
      object-fit: cover;
      height: 100%;
      border-radius: 3px;
    }
  }

  .wp-block-image figcaption,
  .blocks-gallery-caption {
    font-family: $font-family-primary;
    font-weight: $font-weight-normal;
    @include font-1;
    @include themed() {
      color: rgba(theme($secondary), 0.5);
    }
  }

  .wp-block-image figure img {
    @include media-breakpoint-down(md) {
      height: 100%;
      width: 100%;
    }
  }

  .columns-2 {
    .blocks-gallery-grid {
      display: grid;
      grid-column-gap: 26px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .has-background:not(.wp-block-button__link) {
    @extend %medium_width-centered;
    box-sizing: border-box;
    padding: 24px;
    margin-bottom: 41px;
    @include media-breakpoint-up(md) {
      padding: 24px 35px;
    }
  }

  .is-style-checkbox,
  .has-light-grey-background-color,
  .fatchilliRectangleSilver {
    @include themed() {
      background-color: theme($background-light-accent);
    }
  }

  .fc-content-post {
    margin-bottom: 3.75rem;
  }

  .fatchilliRectangleYellow {
    @include themed() {
      background-color: rgba(theme($primary), 0.05);
    }
  }

  ul, ol {
    &.is-style-checkbox,
    &.fatchilliRectangleYellow,
    &.fatchilliRectangleSilver {
      padding-top: 16px;
      list-style: none;
      padding-bottom: 24px;
    }
  }

  .is-style-checkbox,
  .fatchilliRectangleYellow,
  .fatchilliRectangleSilver {
    box-sizing: border-box;
    padding: 24px 24px 0 24px;
    border-top: 3px solid;
    margin-bottom: 41px;
    @include media-breakpoint-up(md) {
      padding: 24px 35px 0 35px;
    }
    @include themed() {
      border-color: theme($primary);
      color: theme($text-dark);
    }

    h2 {
      margin: 0;

      & + hr {
        height: 1px;
        border: none;
        border-top: 1px dotted;
        @include themed() {
          border-color: theme($border-dark);
        }
      }
    }

    ul {
      @extend %thin_width-centered;
      padding-top: 16px;
      list-style: none;
      padding-bottom: 24px;
    }

    li {
      @include font-5;
      font-weight: $font-weight-normal;
      margin-left: 20px;
      position: relative;
      @include themed() {
        color: rgba(theme($text-dark), 0.8);
      }

      &::before {
        content: "+";
        width: 1em;
        margin-right: 12px;
        position: absolute;
        left: -20px;
        @include themed() {
          color: theme($primary);
        }
      }
    }
  }

  [id^=mc_embed] {
    box-sizing: border-box;
    @include themed() {
      background: theme($background-light-accent) !important;
    }

    form {
      box-sizing: border-box !important;
      padding: 24px 34px !important;
    }
  }

  .wp-block-media-text {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-gap: 26px;

    &.has-media-on-the-right {
      > div {
        order: -1;
      }
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr !important;
    }

    @include media-breakpoint-down(md) {
      &.has-media-on-the-right.is-stacked-on-mobile {
        > div {
          order: initial;
        }
      }
    }
  }

  .wp-block-button__link {
    @extend .Button;
    @extend .Button--primary;
    margin-bottom: 16px;
    border-radius: 3px !important;

    &.has-dark-blue-background-color {
      @extend .Button--secondary;
    }
  }

  .wp-block-embed {
    margin-bottom: 20px;

    blockquote[data-secret] {
      display: none;
    }

    iframe {
      @extend %medium_width-centered;
      position: relative !important;
      width: 100% !important;

      .wp-embed {
        padding: 0;
        font-size: inherit;
        font-weight: 400;
        font-family: inherit;
        line-height: inherit;
        color: inherit;
        border: inherit;
        box-shadow: inherit;
        @include themed() {
          background: theme($background-light-accent);
        }
      }
    }

    .twitter-tweet {
      display: flex !important;
      margin: auto 0 !important;
      width: 100% !important;
      max-width: 100% !important;

      iframe {
        flex-grow: 0 !important;
        max-width: 550px;
      }
    }

    &.wp-block-embed-youtube {
      iframe {
        @include media-breakpoint-down(sm) {
          height: 300px;
        }
      }
    }
  }

  .wp-block-jetpack-mailchimp {
    padding-top: 50px;
    padding-bottom: 20px;
    @include themed() {
      background-color: theme($background-light-accent);
    }

    form {
      position: relative;
      margin: 0 20px;
      z-index: $z-index-1;

      .wp-block-button {
        button {
          @include themed() {
            color: theme($text-light);
            background-color: theme($primary);
            border-color: theme($primary);
          }
          &:hover {
            @include themed() {
              background-color: theme($primary-hover);
              border-color: theme($primary-hover);
            }
          }
        }
      }

      > p:first-child {
        margin-bottom: 12px;
      }

      .wp-block-button {
        margin: 0 auto;
        margin-bottom: 12px;
        max-width: 572px;
      }

      @include media-breakpoint-up(md) {
        margin: 0;

        > p:first-child {
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          margin: auto;
        }

        .wp-block-button {
          position: absolute;
          max-width: 100%;
          width: 100%;
          margin-bottom: 0;

          button {
            position: absolute;
            top: 5px;
            right: 52px;
            transform: translateY(0);
          }
        }

        #wp-block-jetpack-mailchimp_consent-text {
          padding-top: 70px;
        }
      }
    }

    input[type=email] {
      @extend .Input--email;
      border: 1px solid;
      @include themed() {
        border-color: theme($border-dark)
      }
    }

    .wp-block-jetpack-mailchimp_notification {
      display: none;
    }

    .is-visible {
      display: table;
      margin: 0 auto
    }
  }

  .rt-reading-time {
    display: none !important;
  }

  .wc-block-grid.has-3-columns ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
      grid-template-columns: inherit;
    }

    li {
      border-bottom: 1px solid;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;
      @include themed() {
        border-color: rgba(theme($secondary), 0.15);
      }
    }

    a {
      text-decoration: none;
      width: 100%;
    }

    .wc-block-grid__product-image {
      flex-grow: 1;
      flex-basis: 100%;
      @extend %image_wrapper-def;
      @include themed() {
        background-color: theme($background-light-accent);
      }

      img {
        @extend %image-def;
        aspect-ratio: 16 / 9;
        object-fit: scale-down;
        padding: 20px 0;
      }
    }


    .wc-block-grid__product-title {
      flex-grow: 1;
      flex-basis: 100%;
      @include font-6-tight;
      margin: 20px 0;
      @include themed() {
        color: theme($text-dark);
      }

      &:hover {
        @include themed() {
          color: theme($text-dark-accent);
        }
      }
    }

    .wc-block-grid__product-onsale {
      display: none;
    }

    .wc-block-grid__product-price {
      flex-grow: 1;
      flex-basis: 50%;
      display: flex;
      align-items: center;
      font-family: $font-family-secondary;
      @include font-3;
      @include themed() {
        color: theme($text-dark-accent);
      }
    }

    .wc-block-grid__product-add-to-cart {
      flex-grow: 1;
      flex-basis: 50%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }

    ins {
      text-decoration: none;
    }

    del {
      display: none;
    }
  }

  .is-grid.columns-3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
      grid-template-columns: inherit;
    }

    li {
      border: 1px solid;
      padding-bottom: 16px;
      @include themed() {
        border-color: theme($text-dark-hover);
      }

      > div:first-child {
        @extend %image_wrapper-def;
        aspect-ratio: 16 / 9;

        img {
          @extend %image-def;
        }
      }

      a {
        @include font-6-tight;
        margin: 16px 0;
        text-decoration: none;
        display: block;
        @include themed() {
          border-color: theme($text-dark-accent-hover);
        }

        &:hover {
          @include themed() {
            border-color: theme($text-dark-accent);
          }
        }
      }

      .wp-block-latest-posts__post-author {
        font-weight: $font-weight-normal;
        font-size: $font-size-4;
        @include themed() {
          color: theme($text-light-accent);
        }
      }

      .wp-block-latest-posts__post-date {
        display: none;
      }

      .wp-block-latest-posts__post-excerpt {
        display: none;
      }
    }
  }

  #wp-sponsors {
    display: none;
  }

  .wp-block-jetpack-slideshow {
    margin-bottom: 26px;

    .wp-block-jetpack-slideshow_slide {
      figure {
        max-height: 100%;
        height: 100%;

        img {
          max-height: 85%;
          object-fit: contain;
          width: 100%;

          @include media-breakpoint-up(md) {
            max-height: 90%;
          }
        }
      }
    }

    a {
      text-decoration: none;
    }

    .swiper-pagination-bullet-active {
      background-color: $black;
    }
  }

  .advgb-columns-1 > .advgb-column {
    flex: none;
    width: 100%
  }

  .advgb-columns-wrapper .advgb-columns.columns-valign-middle {
    align-items: center
  }

  .advgb-columns-wrapper .advgb-columns.columns-valign-bottom {
    align-items: flex-end
  }

  .advgb-columns.columns-valign-full .advgb-column-inner {
    height: 100%
  }

  @include media-breakpoint-up(lg) {
    .advgb-column.advgb-is-default-desktop {
      flex: 1;
      width: auto
    }
  }

  @include media-breakpoint-between(md, lg) {
    .advgb-column.advgb-is-default-tablet {
      flex: 1;
      width: auto
    }
    .advgb-columns.tbl-layout-1-12-12, .advgb-columns.tbl-layout-12-12-1, .advgb-columns.tbl-layout-12x4, .advgb-columns.tbl-layout-12x6, .advgb-columns.tbl-layout-13x6, .advgb-columns.tbl-layout-stacked {
      flex-wrap: wrap
    }
    .advgb-columns.tbl-layout-stacked .advgb-column {
      width: 100% !important
    }
  }

  @include media-breakpoint-down(md) {
    .advgb-column.advgb-is-default-mobile {
      flex: 1;
      width: auto
    }
    .advgb-columns.mbl-layout-1-12-12, .advgb-columns.mbl-layout-12-12-1, .advgb-columns.mbl-layout-12x4, .advgb-columns.mbl-layout-12x6, .advgb-columns.mbl-layout-13x6, .advgb-columns.mbl-layout-stacked {
      flex-wrap: wrap
    }
    .advgb-columns.mbl-layout-stacked .advgb-column {
      width: 100% !important
    }
  }

  @include media-breakpoint-down(sm) {
    .advgb-count-up {
      flex-direction: column
    }
    .advgb-testimonial:not(.slider-view) {
      flex-direction: column
    }
  }

  @include media-breakpoint-down(lg) {
    .advgb-columns.mbl-layout-stacked > .advgb-column {
      margin-right: 0 !important
    }
    .advgb-columns.tbl-layout-stacked.vgutter-10 > .advgb-column {
      margin-bottom: 10px
    }
    .advgb-columns.tbl-layout-stacked.vgutter-20 > .advgb-column {
      margin-bottom: 20px
    }
    .advgb-columns.tbl-layout-stacked.vgutter-30 > .advgb-column {
      margin-bottom: 30px
    }
    .advgb-columns.tbl-layout-stacked.vgutter-40 > .advgb-column {
      margin-bottom: 40px
    }
    .advgb-columns.tbl-layout-stacked.vgutter-50 > .advgb-column {
      margin-bottom: 50px
    }
    .advgb-columns.tbl-layout-stacked.vgutter-70 > .advgb-column {
      margin-bottom: 70px
    }
    .advgb-columns.tbl-layout-stacked.vgutter-90 > .advgb-column {
      margin-bottom: 90px
    }
  }

  @include media-breakpoint-down(md) {
    wp-block, .advgb-columns.mbl-layout-stacked.vgutter-10 > .advgb-column {
      margin-bottom: 10px
    }
    .advgb-columns.mbl-layout-stacked.vgutter-20 > .advgb-column {
      margin-bottom: 20px
    }
    .advgb-columns.mbl-layout-stacked.vgutter-30 > .advgb-column {
      margin-bottom: 30px
    }
    .advgb-columns.mbl-layout-stacked.vgutter-40 > .advgb-column {
      margin-bottom: 40px
    }
    .advgb-columns.mbl-layout-stacked.vgutter-50 > .advgb-column {
      margin-bottom: 50px
    }
    .advgb-columns.mbl-layout-stacked.vgutter-70 > .advgb-column {
      margin-bottom: 70px
    }
    .advgb-columns.mbl-layout-stacked.vgutter-90 > .advgb-column {
      margin-bottom: 90px
    }
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns {
    display: flex;
    margin: 0 auto
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns .advgb-column {
    flex-basis: 0
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.gutter-10 > .advgb-column:not(:first-child) {
    margin-left: 10px
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.gutter-20 > .advgb-column:not(:first-child) {
    margin-left: 20px
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.gutter-30 > .advgb-column:not(:first-child) {
    margin-left: 30px
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.gutter-40 > .advgb-column:not(:first-child) {
    margin-left: 40px
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.gutter-50 > .advgb-column:not(:first-child) {
    margin-left: 50px
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.gutter-70 > .advgb-column:not(:first-child) {
    margin-left: 70px
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.gutter-90 > .advgb-column:not(:first-child) {
    margin-left: 90px
  }

  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.advgb-columns-1 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.advgb-columns-2 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.advgb-columns-3 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.advgb-columns-4 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.advgb-columns-5 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.advgb-columns-6 > .advgb-column {
    flex-grow: 1;
    min-width: 0
  }

  @include media-breakpoint-up(lg) {
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-23-13 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-13-23 > .advgb-column:nth-of-type(2) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-14-34 > .advgb-column:nth-of-type(2) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-34-14 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-15-45 > .advgb-column:nth-of-type(2) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-45-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12-14-14 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-14-14-12 > .advgb-column:nth-of-type(3) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-14-12-14 > .advgb-column:nth-of-type(2) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-15-35-15 > .advgb-column:nth-of-type(2) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-35-15-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-15-15-35 > .advgb-column:nth-of-type(3) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-16-46-16 > .advgb-column:nth-of-type(2) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-1-12-12 {
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-1-12-12 > .advgb-column:nth-of-type(1) {
      width: 100%;
      flex-basis: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-1-12-12 > .advgb-column:nth-of-type(2) {
      flex-grow: 1;
      margin-left: 0
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-1-12-12 > .advgb-column:nth-of-type(3) {
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12-12-1 {
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12-12-1 > .advgb-column:nth-of-type(1) {
      margin-left: 0;
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12-12-1 > .advgb-column:nth-of-type(2) {
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12-12-1 > .advgb-column:nth-of-type(3) {
      width: 100%;
      flex-basis: unset !important;
      margin-left: 0
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-36-16-16-16 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-16-16-16-36 > .advgb-column:nth-of-type(4) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-15-15-15-25 > .advgb-column:nth-of-type(4) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-25-15-15-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12x4, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12x6 {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12x4 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-12x6 > .advgb-column {
      flex-basis: 50% !important;
      margin-left: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-13x6 {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-13x6 > .advgb-column {
      flex-basis: 33.33% !important;
      margin-left: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.layout-stacked > .advgb-column {
      width: 100%;
      flex-basis: unset !important;
      margin-left: 0 !important
    }
  }

  @include media-breakpoint-between(md, lg) {
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-23-13 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-13-23 > .advgb-column:nth-of-type(2) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-14-34 > .advgb-column:nth-of-type(2) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-34-14 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-15-45 > .advgb-column:nth-of-type(2) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-45-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12-14-14 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-14-14-12 > .advgb-column:nth-of-type(3) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-14-12-14 > .advgb-column:nth-of-type(2) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-15-35-15 > .advgb-column:nth-of-type(2) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-35-15-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-15-15-35 > .advgb-column:nth-of-type(3) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-16-46-16 > .advgb-column:nth-of-type(2) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-1-12-12 {
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-1-12-12 > .advgb-column:nth-of-type(1) {
      width: 100%;
      flex-basis: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-1-12-12 > .advgb-column:nth-of-type(2) {
      flex-grow: 1;
      margin-left: 0
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-1-12-12 > .advgb-column:nth-of-type(3) {
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12-12-1 {
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12-12-1 > .advgb-column:nth-of-type(1) {
      margin-left: 0;
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12-12-1 > .advgb-column:nth-of-type(2) {
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12-12-1 > .advgb-column:nth-of-type(3) {
      width: 100%;
      flex-basis: unset !important;
      margin-left: 0
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-36-16-16-16 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-16-16-16-36 > .advgb-column:nth-of-type(4) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-15-15-15-25 > .advgb-column:nth-of-type(4) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-25-15-15-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12x4, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12x6 {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12x4 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-12x6 > .advgb-column {
      flex-basis: 50% !important;
      margin-left: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-13x6 {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-13x6 > .advgb-column {
      flex-basis: 33.33% !important;
      margin-left: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.tbl-layout-stacked > .advgb-column {
      width: 100%;
      flex-basis: unset !important;
      margin-left: 0 !important
    }
  }

  @include media-breakpoint-down(md) {
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-23-13 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-13-23 > .advgb-column:nth-of-type(2) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-14-34 > .advgb-column:nth-of-type(2) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-34-14 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-15-45 > .advgb-column:nth-of-type(2) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-45-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12-14-14 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-14-14-12 > .advgb-column:nth-of-type(3) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-14-12-14 > .advgb-column:nth-of-type(2) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-15-35-15 > .advgb-column:nth-of-type(2) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-35-15-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-15-15-35 > .advgb-column:nth-of-type(3) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-16-46-16 > .advgb-column:nth-of-type(2) {
      flex-grow: 4
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-1-12-12 {
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-1-12-12 > .advgb-column:nth-of-type(1) {
      width: 100%;
      flex-basis: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-1-12-12 > .advgb-column:nth-of-type(2) {
      flex-grow: 1;
      margin-left: 0
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-1-12-12 > .advgb-column:nth-of-type(3) {
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12-12-1 {
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12-12-1 > .advgb-column:nth-of-type(1) {
      margin-left: 0;
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12-12-1 > .advgb-column:nth-of-type(2) {
      flex-grow: 1
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12-12-1 > .advgb-column:nth-of-type(3) {
      width: 100%;
      flex-basis: unset !important;
      margin-left: 0
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-36-16-16-16 > .advgb-column:nth-of-type(1) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-16-16-16-36 > .advgb-column:nth-of-type(4) {
      flex-grow: 3
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-15-15-15-25 > .advgb-column:nth-of-type(4) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-25-15-15-15 > .advgb-column:nth-of-type(1) {
      flex-grow: 2
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12x4, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12x6 {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12x4 > .advgb-column, .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-12x6 > .advgb-column {
      flex-basis: 50% !important;
      margin-left: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-13x6 {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-13x6 > .advgb-column {
      flex-basis: 33.33% !important;
      margin-left: unset !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-stacked > .advgb-column {
      width: 100%;
      flex-basis: unset !important;
      margin-left: 0 !important
    }
    .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.order-rtl {
      flex-wrap: wrap-reverse;
      -moz-flex-wrap: wrap-reverse
    }
  }



  .wp-block-table.is-style-stripes tr:nth-child(odd) {
    background-color: #ededed;
  }

  .wp-block-table {
    overflow-x: auto;
    @include font-default;
    font-family: $font-family-primary;
    font-weight: $font-weight-normal;
    margin-bottom: 26px;
    padding: 0;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      @include font-5;
    }
    @include themed() {
      color: rgba(theme($text-dark), 0.8);
    }
  }

  .wp-block-table table {
    border-collapse: collapse;
    width: 100%
  }

  .wp-block-table .has-fixed-layout {
    table-layout: fixed;
    width: 100%
  }

  .wp-block-table .has-fixed-layout td,.wp-block-table .has-fixed-layout th {
    word-break: break-word
  }

  .wp-block-table.aligncenter,.wp-block-table.alignleft,.wp-block-table.alignright {
    display: table;
    width: auto
  }

  .wp-block-table.aligncenter td,.wp-block-table.aligncenter th,.wp-block-table.alignleft td,.wp-block-table.alignleft th,.wp-block-table.alignright td,.wp-block-table.alignright th {
    word-break: break-word
  }

  .wp-block-table .has-subtle-light-gray-background-color {
    background-color: #f3f4f5
  }

  .wp-block-table .has-subtle-pale-green-background-color {
    background-color: #e9fbe5
  }

  .wp-block-table .has-subtle-pale-blue-background-color {
    background-color: #e7f5fe
  }

  .wp-block-table .has-subtle-pale-pink-background-color {
    background-color: #fcf0ef
  }

  .wp-block-table.is-style-stripes {
    background-color: initial;
    border-bottom: 1px solid #f0f0f0;
    border-collapse: inherit;
    border-spacing: 0
  }

  .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: #f0f0f0
  }

  .wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
    background-color: #f3f4f5
  }

  .wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
    background-color: #e9fbe5
  }

  .wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
    background-color: #e7f5fe
  }

  .wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
    background-color: #fcf0ef
  }

  .wp-block-table.is-style-stripes td,.wp-block-table.is-style-stripes th {
    border-color: #0000
  }

  .wp-block-table .has-border-color>*,.wp-block-table .has-border-color td,.wp-block-table .has-border-color th,.wp-block-table .has-border-color tr {
    border-color: inherit
  }

  .wp-block-table table[style*=border-top-color]>*,.wp-block-table table[style*=border-top-color]>* td,.wp-block-table table[style*=border-top-color]>* th,.wp-block-table table[style*=border-top-color] tr:first-child,.wp-block-table table[style*=border-top-color] tr:first-child td,.wp-block-table table[style*=border-top-color] tr:first-child th {
    border-top-color: inherit
  }

  .wp-block-table table[style*=border-top-color] tr:not(:first-child) {
    border-top-color: initial
  }

  .wp-block-table table[style*=border-right-color]>*,.wp-block-table table[style*=border-right-color] td:last-child,.wp-block-table table[style*=border-right-color] th,.wp-block-table table[style*=border-right-color] tr {
    border-right-color: inherit
  }

  .wp-block-table table[style*=border-bottom-color]>*,.wp-block-table table[style*=border-bottom-color]>* td,.wp-block-table table[style*=border-bottom-color]>* th,.wp-block-table table[style*=border-bottom-color] tr:last-child,.wp-block-table table[style*=border-bottom-color] tr:last-child td,.wp-block-table table[style*=border-bottom-color] tr:last-child th {
    border-bottom-color: inherit
  }

  .wp-block-table table[style*=border-bottom-color] tr:not(:last-child) {
    border-bottom-color: initial
  }

  .wp-block-table table[style*=border-left-color]>*,.wp-block-table table[style*=border-left-color] td:first-child,.wp-block-table table[style*=border-left-color] th,.wp-block-table table[style*=border-left-color] tr {
    border-left-color: inherit
  }

  .wp-block-table table[style*=border-style]>*,.wp-block-table table[style*=border-style] td,.wp-block-table table[style*=border-style] th,.wp-block-table table[style*=border-style] tr {
    border-style: inherit
  }

  .wp-block-table table[style*=border-width]>*,.wp-block-table table[style*=border-width] td,.wp-block-table table[style*=border-width] th,.wp-block-table table[style*=border-width] tr {
    border-style: inherit;
    border-width: inherit
  }


  .wp-block-table thead {
    border-bottom: 3px solid
  }

  .wp-block-table tfoot {
    border-top: 3px solid
  }

  .wp-block-table td,.wp-block-table th {
    border: 1px solid;
    padding: .5em;
    word-break: normal
  }

  .wp-block-table figcaption {
    color: #555;
    font-size: 13px;
    text-align: center
  }

  .wp-block[data-align=center]>.wp-block-table,.wp-block[data-align=left]>.wp-block-table,.wp-block[data-align=right]>.wp-block-table {
    height: auto
  }

  .wp-block[data-align=center]>.wp-block-table table,.wp-block[data-align=left]>.wp-block-table table,.wp-block[data-align=right]>.wp-block-table table {
    width: auto
  }

  .wp-block[data-align=center]>.wp-block-table td,.wp-block[data-align=center]>.wp-block-table th,.wp-block[data-align=left]>.wp-block-table td,.wp-block[data-align=left]>.wp-block-table th,.wp-block[data-align=right]>.wp-block-table td,.wp-block[data-align=right]>.wp-block-table th {
    word-break: break-word
  }

  .wp-block[data-align=center]>.wp-block-table {
    text-align: initial
  }

  .wp-block[data-align=center]>.wp-block-table table {
    margin: 0 auto
  }

  .wp-block-table td,.wp-block-table th {
    border: 1px solid
  }

  .wp-block-table td.is-selected,.wp-block-table th.is-selected {
    border-color: var(--wp-admin-theme-color);
    border-style: double;
    box-shadow: inset 0 0 0 1px var(--wp-admin-theme-color)
  }

  .wp-block-table figcaption {
    color: #555;
    font-size: 13px;
    text-align: center
  }

  .wp-block-table table.has-individual-borders>*,.wp-block-table table.has-individual-borders td,.wp-block-table table.has-individual-borders th,.wp-block-table table.has-individual-borders tr {
    border: 1px solid
  }


  .wp-block-jetpack-tiled-gallery {
    margin: 0 auto 1.5em
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__item img {
    border-radius: 50%
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row {
    flex-grow: 1;
    width: 100%
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-1 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-1 .tiled-gallery__col {
    width: 100%
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-2 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-2 .tiled-gallery__col {
    width: calc(50% - 2px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-3 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-3 .tiled-gallery__col {
    width: calc(33.33333% - 2.66667px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-4 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-4 .tiled-gallery__col {
    width: calc(25% - 3px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-5 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-5 .tiled-gallery__col {
    width: calc(20% - 3.2px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-6 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-6 .tiled-gallery__col {
    width: calc(16.66667% - 3.33333px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-7 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-7 .tiled-gallery__col {
    width: calc(14.28571% - 3.42857px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-8 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-8 .tiled-gallery__col {
    width: calc(12.5% - 3.5px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-9 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-9 .tiled-gallery__col {
    width: calc(11.11111% - 3.55556px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-10 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-10 .tiled-gallery__col {
    width: calc(10% - 3.6px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-11 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-11 .tiled-gallery__col {
    width: calc(9.09091% - 3.63636px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-12 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-12 .tiled-gallery__col {
    width: calc(8.33333% - 3.66667px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-13 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-13 .tiled-gallery__col {
    width: calc(7.69231% - 3.69231px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-14 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-14 .tiled-gallery__col {
    width: calc(7.14286% - 3.71429px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-15 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-15 .tiled-gallery__col {
    width: calc(6.66667% - 3.73333px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-16 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-16 .tiled-gallery__col {
    width: calc(6.25% - 3.75px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-17 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-17 .tiled-gallery__col {
    width: calc(5.88235% - 3.76471px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-18 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-18 .tiled-gallery__col {
    width: calc(5.55556% - 3.77778px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-19 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-19 .tiled-gallery__col {
    width: calc(5.26316% - 3.78947px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__row.columns-20 .tiled-gallery__col,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__row.columns-20 .tiled-gallery__col {
    width: calc(5% - 3.8px)
  }

  .wp-block-jetpack-tiled-gallery.is-style-columns .tiled-gallery__item,.wp-block-jetpack-tiled-gallery.is-style-rectangular .tiled-gallery__item {
    display: flex
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-1 .tiled-gallery__item img {
    border-radius: 1px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-2 .tiled-gallery__item img {
    border-radius: 2px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-3 .tiled-gallery__item img {
    border-radius: 3px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-4 .tiled-gallery__item img {
    border-radius: 4px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-5 .tiled-gallery__item img {
    border-radius: 5px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-6 .tiled-gallery__item img {
    border-radius: 6px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-7 .tiled-gallery__item img {
    border-radius: 7px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-8 .tiled-gallery__item img {
    border-radius: 8px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-9 .tiled-gallery__item img {
    border-radius: 9px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-10 .tiled-gallery__item img {
    border-radius: 10px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-11 .tiled-gallery__item img {
    border-radius: 11px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-12 .tiled-gallery__item img {
    border-radius: 12px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-13 .tiled-gallery__item img {
    border-radius: 13px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-14 .tiled-gallery__item img {
    border-radius: 14px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-15 .tiled-gallery__item img {
    border-radius: 15px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-16 .tiled-gallery__item img {
    border-radius: 16px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-17 .tiled-gallery__item img {
    border-radius: 17px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-18 .tiled-gallery__item img {
    border-radius: 18px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-19 .tiled-gallery__item img {
    border-radius: 19px
  }

  .wp-block-jetpack-tiled-gallery.has-rounded-corners-20 .tiled-gallery__item img {
    border-radius: 20px
  }

  .tiled-gallery__gallery {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%
  }

  .tiled-gallery__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    width: 100%
  }

  .tiled-gallery__row+.tiled-gallery__row {
    margin-top: 4px
  }

  .tiled-gallery__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0
  }

  .tiled-gallery__col+.tiled-gallery__col {
    margin-left: 4px
  }

  .tiled-gallery__item {
    flex-grow: 1;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative
  }

  .tiled-gallery__item.filter__black-and-white {
    filter: grayscale(100%)
  }

  .tiled-gallery__item.filter__sepia {
    filter: sepia(100%)
  }

  .tiled-gallery__item.filter__1977 {
    filter: contrast(1.1) brightness(1.1) saturate(1.3);
    position: relative
  }

  .tiled-gallery__item.filter__1977 img {
    width: 100%;
    z-index: 1
  }

  .tiled-gallery__item.filter__1977:before {
    z-index: 2
  }

  .tiled-gallery__item.filter__1977:after,.tiled-gallery__item.filter__1977:before {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%
  }

  .tiled-gallery__item.filter__1977:after {
    background: rgba(243,106,188,.3);
    mix-blend-mode: screen;
    z-index: 3
  }

  .tiled-gallery__item.filter__clarendon {
    filter: contrast(1.2) saturate(1.35);
    position: relative
  }

  .tiled-gallery__item.filter__clarendon img {
    width: 100%;
    z-index: 1
  }

  .tiled-gallery__item.filter__clarendon:before {
    z-index: 2
  }

  .tiled-gallery__item.filter__clarendon:after,.tiled-gallery__item.filter__clarendon:before {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%
  }

  .tiled-gallery__item.filter__clarendon:after {
    z-index: 3
  }

  .tiled-gallery__item.filter__clarendon:before {
    background: rgba(127,187,227,.2);
    mix-blend-mode: overlay
  }

  .tiled-gallery__item.filter__gingham {
    filter: brightness(1.05) hue-rotate(-10deg);
    position: relative
  }

  .tiled-gallery__item.filter__gingham img {
    width: 100%;
    z-index: 1
  }

  .tiled-gallery__item.filter__gingham:before {
    z-index: 2
  }

  .tiled-gallery__item.filter__gingham:after,.tiled-gallery__item.filter__gingham:before {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%
  }

  .tiled-gallery__item.filter__gingham:after {
    background: #e6e6fa;
    mix-blend-mode: soft-light;
    z-index: 3
  }

  .tiled-gallery__item+.tiled-gallery__item {
    margin-top: 4px
  }

  .tiled-gallery__item>img {
    background-color: rgba(0,0,0,.1)
  }

  .tiled-gallery__item>a,.tiled-gallery__item>a>img,.tiled-gallery__item>img {
    display: block;
    height: auto;
    margin: 0;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    padding: 0;
    width: 100%
  }

  .is-email .tiled-gallery__gallery {
    display: block
  }

  @keyframes tiled-gallery-img-placeholder {
    0% {
      background-color: #f6f7f7
    }

    50% {
      background-color: hsla(180,6%,97%,.5)
    }

    to {
      background-color: #f6f7f7
    }
  }

  .wp-block-jetpack-tiled-gallery {
    padding-left: 4px;
    padding-right: 4px
  }

  .wp-block-jetpack-tiled-gallery.is-style-circle .tiled-gallery__item.is-transient img,.wp-block-jetpack-tiled-gallery.is-style-square .tiled-gallery__item.is-transient img {
    margin-bottom: 100%
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item>img:focus {
    outline: none
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item>img {
    animation: tiled-gallery-img-placeholder 1.6s ease-in-out infinite
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected {
    filter: none;
    outline: 4px solid #0085ba
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected:after,.wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected:before {
    content: none
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-transient {
    height: 100%;
    width: 100%
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-transient img {
    background-position: 50%;
    background-size: cover;
    height: 100%;
    opacity: .3;
    width: 100%
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu,.wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu {
    background: #fff;
    border: 1px solid rgba(30,30,30,.62);
    border-radius: 2px;
    transition: box-shadow .2s ease-out
  }

  @media(prefers-reduced-motion:reduce) {
    .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu,.wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu {
      transition-delay: 0s;
      transition-duration: 0s
    }
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu:hover,.wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,.05)
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu .components-button,.wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu .components-button {
    color: rgba(30,30,30,.62);
    height: 24px;
    padding: 2px
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu .components-button:not(:disabled):not([aria-disabled=true]):not(.is-secondary):hover,.wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu .components-button:not(:disabled):not([aria-disabled=true]):not(.is-secondary):hover {
    box-shadow: none
  }

  @media(min-width: 600px) {
    .columns-7 .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu .components-button,.columns-7 .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu .components-button,.columns-8 .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu .components-button,.columns-8 .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu .components-button {
      height:inherit;
      padding: 0;
      width: inherit
    }
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__inline-menu .components-button:focus,.wp-block-jetpack-tiled-gallery .tiled-gallery__item.is-selected .tiled-gallery__item__move-menu .components-button:focus {
    color: inherit
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__add-item {
    margin-top: 4px;
    width: 100%
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__add-item .components-button.tiled-gallery__add-item-button,.wp-block-jetpack-tiled-gallery .tiled-gallery__add-item .components-form-file-upload {
    width: 100%
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__add-item .components-button.tiled-gallery__add-item-button {
    border: none;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__add-item .components-button.tiled-gallery__add-item-button .dashicon {
    margin-top: 10px
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__add-item .components-button.tiled-gallery__add-item-button:focus,.wp-block-jetpack-tiled-gallery .tiled-gallery__add-item .components-button.tiled-gallery__add-item-button:hover {
    border: 1px solid #949494
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item__inline-menu,.wp-block-jetpack-tiled-gallery .tiled-gallery__item__move-menu {
    display: inline-flex;
    margin: 8px;
    z-index: 20
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item__inline-menu .components-button,.wp-block-jetpack-tiled-gallery .tiled-gallery__item__move-menu .components-button {
    color: transparent
  }

  @media(min-width: 600px) {
    .columns-7 .wp-block-jetpack-tiled-gallery .tiled-gallery__item__inline-menu,.columns-7 .wp-block-jetpack-tiled-gallery .tiled-gallery__item__move-menu,.columns-8 .wp-block-jetpack-tiled-gallery .tiled-gallery__item__inline-menu,.columns-8 .wp-block-jetpack-tiled-gallery .tiled-gallery__item__move-menu {
      padding:2px
    }
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item__inline-menu {
    position: absolute;
    right: -2px;
    top: -2px
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item__move-menu {
    left: -2px;
    position: absolute;
    top: -2px
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item__move-backward,.wp-block-jetpack-tiled-gallery .tiled-gallery__item__move-forward,.wp-block-jetpack-tiled-gallery .tiled-gallery__item__remove {
    padding: 0
  }

  .wp-block-jetpack-tiled-gallery .tiled-gallery__item .components-spinner {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
  }

  .block-editor-block-preview__content .wp-block-jetpack-tiled-gallery .block-editor-media-placeholder {
    display: none
  }

  .tiled-gallery__filter-picker-menu {
    padding: 7px
  }

  .tiled-gallery__filter-picker-menu .components-menu-item__button+.components-menu-item__button {
    margin-top: 2px
  }

  .tiled-gallery__filter-picker-menu .components-menu-item__button.is-active {
    box-shadow: 0 0 0 2px #949494!important;
    color: #1e1e1e
  }
}