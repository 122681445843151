.ProductBottomBuy {
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.ProductBottomBuy--Wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-gap: 10px;
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-columns: inherit;
    grid-gap: 40px;
  }
}

.ProductBottomBuy--Image {
  justify-self: center;
  height: 140px;
  width: 100%;
}

.ProductBottomBuy--Headlines {
  align-self: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
  }
}

.ProductBottomBuy--Caption {
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  @include font-3-tight;
  @include themed() {
    color: theme($text-dark-accent);
  }
}

.ProductBottomBuy--Title {
  @include font-8;
  text-align: center;
  @include themed() {
    color: theme($text-dark);
  }

  @include media-breakpoint-up(md) {
    text-align: inherit;
  }
}