.ProductForm--Wrapper {
  display: flex;
  justify-content: center;
  min-width: 100%;
}

.ProductForm {
  max-width: 468px;
  margin: 0 auto;
  box-sizing: border-box;
  @include themed() {
    background-color: theme($background-light);
  }

  .Layout--TwoColumn-Grid {
    grid-gap: 0.5rem;
  }
}

.ProductForm--Title {
  @include font-7;
  @include themed() {
    color: theme($secondary);
  }

  @include media-breakpoint-up(md) {
    font-size: $font-size-9;
  }
}

.ProductForm--Caption {
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  @include font-1;
  @include themed() {
    color: theme($text-light-accent);
  }
}

.ProductForm--Input {
  border: 1px solid;
  padding-right: 17px;
  @include themed() {
    background-color: theme($background-light-accent);
    border-color: theme($border-dark);
  }
}