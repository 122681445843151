.PartnersPromo {
  text-align: center;
  @include themed() {
    border-color: theme($background-light-accent);
  }
}

.PartnersPromo--image {
  display: inline-block;
  max-height: 17px;
  opacity: 0.25;

  @include media-breakpoint-up(md) {
    max-height: 27px;
  }
}