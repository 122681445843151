.NumberedVerticalListPosts--Items {
  grid-template-columns: 32px 1fr;
}

.NumberedVerticalListPosts--Items-Number {
  @include font-2;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include themed() {
    background-color: theme($background-light-accent);
  }
}