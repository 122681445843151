.FeaturedHeaderSection {
  width: 100%;
  max-width: 100%;
  @include themed() {
    background: theme($background-dark);
    @include media-breakpoint-up(md) {
      background: theme($background-dark) linear-gradient(to right, transparent 0%, rgba(theme($background-dark-accent), 0) 50%, rgba(theme($background-dark-accent), 0.2) 50%, rgba(theme($background-dark-accent), 0.2));
    }
  }

  .Author {
    @include themed() {
      color: theme($text-light-accent);
    }

    span {
      @include themed() {
        color: theme($primary);
      }

      &:hover {
        @include themed() {
          color: theme($primary-hover);
          text-decoration: underline;
        }
      }
    }
  }

  .DateAndAuthor-date {
    @include themed() {
      color: theme($text-light-accent);
    }
  }

  .EstimatedReadTime--time {
    span {
      @include themed() {
        color: theme($text-light);
      }
    }
  }
}