@mixin generateSpacing($prefix, $properties) {
  // List of sizes to generate $spacers defined in _config.scss
  // $spacer: 1em; - setup by bootstrap

  // Loop through all of the sizes
  @for $i from 0 through 40 {
    // Create the rule
    .#{$prefix}-#{$i} {
      @each $property in $properties {
        #{$property}: $i*0.25em;
      }
    }
  }
}

/* EXAMPLE OF GENERATED VALUE

.pt-10 {
  padding-top: 2.75rem;
}

*/

@mixin generateSpacingWithBreakpoints($prefix, $properties) {
  @for $i from 0 through 40 {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        .#{$prefix}-#{$breakpoint}-#{$i} {
          @each $property in $properties {
            #{$property}: $i*0.25em;
          }
        }
      }
    }
  }
}

/* EXAMPLE OF GENERATED VALUE

.pt-xxl-10 {
  @media (min-width: 1400px) {
    padding-top: 2.75rem;
  }
}

*/

$spacingProperties: [
  [m, [margin-left, margin-right, margin-top, margin-bottom]],
  [p, [padding-left, padding-right, padding-top, padding-bottom]],
  [mx, [margin-left, margin-right]],
  [my, [margin-top, margin-bottom]],
  [px, [padding-left, padding-right]],
  [py, [padding-top, padding-bottom]],
  [ml, [margin-left]],
  [mt, [margin-top]],
  [mb, [margin-bottom]],
  [mr, [margin-right]],
  [pl, [padding-left]],
  [pt, [padding-top]],
  [pb, [padding-bottom]],
  [pr, [padding-right]]
];

@each $property in $spacingProperties {
  @include generateSpacing(nth($property, 1), nth($property, 2));
  @include generateSpacingWithBreakpoints(nth($property, 1), nth($property, 2));
}

$displayProperties: [
  inherit, inline, inline-block, block, flex, inline-flex, grid, none
];

@each $property in $displayProperties {
  .display-#{$property} {
    display: $property;
  }
}

/* EXAMPLE OF GENERATED VALUE

.display-flex {
  display: flex;
}

*/

@each $property in $displayProperties {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-only($breakpoint) {
      .display-#{$breakpoint}-#{$property} {
        display: $property;
      }
    }
  }
}

/* EXAMPLE OF GENERATED VALUE

.display-lg-flex {
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .display-flex {
      display: flex;
    }
  }
}

*/
