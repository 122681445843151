.ProductPage--Wrapper {
  display: grid;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 352px;
    grid-gap: 26px;
  }
}

.ProductPage--Content {
  @extend %wordpress-content;
}

.ProductsPage--Authors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 26px;
}

.ProductPage--Recommended {
  text-align: center;
  background-image: url("/assets/img/top-decoration.svg");
  background-position: calc(100% + 10px) top;
  background-repeat: no-repeat;
  background-size: 94px 110px;
  @include themed() {
    background-color: theme($background-dark);
  }
}

.ProductPage--Recommended-Text {
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  @include font-6-tight;
  @include themed() {
    color: theme($text-light);
  }
}

.ProductPage--Recommended-Caption, .ProductPage--Recommended-By {
  @include font-3;
  @include themed() {
    color: theme($text-light);
  }
}

.ProductPage--Recommended-Caption {
  font-family: $font-family-secondary;
  @include themed() {
    color: rgba(theme($text-light), 0.5);
  }
}

.ProductPage--Recommended-By span {
  @include themed() {
    color: theme($primary);
  }
}

.ProductPage--Recommended-Image {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.ProductPage--ExploreMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-image: url("/assets/img/top-decoration-dark.svg");
  background-position: calc(100% + 10px) top;
  background-repeat: no-repeat;
  background-size: 94px 110px;
  @include themed() {
    background-color: theme($background-light-accent);
  }

  @include media-breakpoint-up(md) {
    text-align: inherit;
    flex-direction: row;
    background-position: 60% top;
  }

  p {
    @include font-8-tight;
    @include themed() {
      color: theme($secondary);
    }
  }
}

.ProductPage--Excerpt {
  display: grid;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(md) {
    grid-template-columns: 395px 1fr;
    grid-gap: 100px;
  }
}

.ProductPage--Excerpt-Image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/img/productDetail-decoration-small.svg");
  background-repeat: no-repeat;
  background-position: center;

  img {
    max-height: 177px;
    max-width: 230px;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    justify-content: end;
    align-items: inherit;
    background-image: url("/assets/img/productDetail-decoration.svg");
    background-position: left center;
    img {
      object-fit: cover;
      width: 100%;
      max-width: 308px;
      max-height: 100%;
    }

  }
}

.ProductPage--Excerpt-Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ProductPage--Excerpt-Caption {
  font-weight: $font-weight-normal;
  font-family: $font-family-secondary;
  @include font-4-tight;
  @include themed() {
    color: theme($primary);
  }
}

.ProductPage--Excerpt-Text {
  @include font-7-tight;
  @include themed() {
    color: theme($secondary);
  }

  @include media-breakpoint-up(md) {
    @include font-8-loose;
  }
}

.ProductPage--Excerpt-Logos {
  opacity: 0.2;
}