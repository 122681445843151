.HeroPost {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.HeroPost--figure {
  @extend %image_wrapper-def;
  position: relative;
  z-index: $z-index-1;
  display: grid;
  grid-template-rows: 1fr min-content;

  @include media-breakpoint-up(md) {
    grid-template-rows: 1fr;
    grid-template-columns: 150px auto;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 198px auto;
  }
}

.HeroPost--figure-Img {
  @extend %image-def;
  aspect-ratio: 3 / 2;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.HeroPost--figcaption {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HeroPost--figcaption-Title {
  @include font-6-tight;
  font-weight: $font-weight-semibold;
}

.HeroPost--figcaption-TitleLink {
  text-decoration: none;
  @include themed() {
    color: theme($text-light);
  }

  &:hover {
    @include themed() {
      color: theme($text-light-hover);
    }
  }
}