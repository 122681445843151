.ChooseNewsletterOption {
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 3px;
  @include themed() {
    border-color: theme($border-dark);
  }

  &:hover {
    @include themed() {
      border-color: theme($border-light);
    }
  }

  &.ChooseNewsletterOption--Selected {
    @include themed() {
      border-color: theme($border-light);
    }

    .ChooseNewsletterOption--Selector div {
      @include themed() {
        background-color: theme($primary);
        border-color: theme($primary);
      }
    }

    path {
      fill: $white;
    }
  }
}

.ChooseNewsletterOption--SelectorTitlePrice {
  display: grid;
  grid-template-columns: 65px 1fr;
}

.ChooseNewsletterOption--Selector {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border: 1px solid;
    border-radius: 3px;
    box-sizing: border-box;
    @include themed() {
      background-color: theme($background-light-accent);
      border-color: theme($border-dark);
    }
  }

  svg {
    width: 12px;

    path {
      @include themed() {
        fill: theme($background-light-accent);
      }
    }
  }
}

.ChooseNewsletterOption--TitlePrice {
  display: flex;
  justify-content: space-between;
}

.ChooseNewsletterOption--Title {
  h4 {
    @include font-6-tight;
  }

  p {
    @include font-2;
    font-weight: $font-weight-normal;
    @include themed() {
      color: theme($text-dark-accent);
    }
  }
}

.ChooseNewsletterOption--Price {
  @include font-6-loose;
  font-family: $font-family-secondary;
  @include themed() {
    color: theme($primary);
  }
}

.ChooseNewsletterOption--Description {
  p {
    @include font-4;
    font-weight: $font-weight-normal;
    width: 95%;
    @include themed() {
      color: theme($text-dark-accent);
    }
  }

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 65px 1fr;
  }
}