.FooterSocialNav .IconLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    height: 25px;
    @include media-breakpoint-up(md) {
      width: 14px;
      height: 14px;
    }
  }
}