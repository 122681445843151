.CardPost {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardPost--figure {
  position: relative;
  z-index: $z-index-1;
}

.CardPost--figure-ImgWrapper {
  @extend %image_wrapper-def;
}

.CardPost--figure-Img {
  @extend %image-def;
  aspect-ratio: 16 / 9;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.CardPost--figcaption-Title {
  @include font-6-tight;
  margin: 8px 0 24px 0;
}

.CardPost--figcaption-TitleLink {
  text-decoration: none;
  @include themed() {
    color: theme($text-dark);
  }

  &:hover {
    @include themed() {
      color: theme($text-dark-hover);
    }
  }
}

.CardPost--action {
  @extend %action_bar-def;
}
