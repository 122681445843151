.Overlay {
  position: absolute;
  z-index: $z-index-2;
  width: 100%;
}

.Overlay--OneThirdBottom-White {
  height: 33%;
  bottom: 0;
  @include themed() {
    background: theme($background-light);
    background: linear-gradient(0deg, rgba(theme($background-light),1) 0%, rgba(theme($background-light),0) 100%);
  }
}