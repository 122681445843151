.DateAndAuthor {
  font-weight: $font-weight-normal;
  font-size: $font-size-4;
  line-height: 20px;
  @include themed() {
    color: theme($text-dark-accent);
  }
}

.DateAndAuthor-date {
  font-family: $font-family-secondary;

  &::after {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 2px;
    display: inline-block;
    margin: 3px 6px;
    @include themed() {
      background-color: theme($text-light-accent);
    }
  }
}
