.PostDetailAuthorSocials {
  display: flex;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }

  .IconLink[class*=" Button--"] svg {
    padding: 0.5rem;
  }
}