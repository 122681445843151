.CookiesSettings {
  position: fixed;
  z-index: $z-index-5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  width: 100%;
  border-radius: 5px;
  @include themed() {
    background-color: theme($background-light);
    color: theme($text-dark);
  }
}

.CookiesSettings--wrapper {
  display: grid;
  grid-template-columns: minmax(180px, 300px) minmax(450px, 1fr);
}

.CookiesSettings--logo {
  width: 100%;
  @include themed() {
    border-bottom: 1px solid theme($border-dark);
  }

  svg {
    width: 120px;
  }
}

.CookiesSettings--right {
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.CookiesSettings--right-buttons {
  @include themed() {
    border-top: 1px solid theme($border-dark);
  }
  display: flex;
  justify-content: space-between;
}

.CookiesSettings--right-title {
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
  @include font-7;
}

.CookiesSettings--right-text {
  font-family: $font-family-primary;
  font-weight: $font-weight-normal;
  @include font-default;
}

.CookiesSettings--switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #2196F3;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
}

.CookiesSettings--slider {
  position: absolute;
  cursor: not-allowed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 30px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
}