.Author {
  font-weight: $font-weight-normal;
  font-size: $font-size-4;
  text-decoration: none;

  @include themed() {
    color: theme($text-dark-accent);
  }

  span {
    @include themed() {
      color: theme($text-dark-accent);
    }
    text-decoration: none;

    &:hover {
      @include themed() {
        color: theme($text-dark-accent-hover);
      }
    }
  }
}
