.Layout--container {
  width: $base_page_width;
  max-width: calc(100% - 40px);
  margin: 0 auto;
  padding: 0 20px;
  &-wide {
    @extend .Layout--container;
    width: 1412px;
  }
}

.Layout--ThreeColumnCentered-Flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .Layout--left {
    text-align: left;
    flex: 6;
  }

  .Layout--right {
    text-align: right;
    flex: 6;
  }

  .Layout--center {
    text-align: center;
    flex: 1;
  }
}

.Layout--ThreeColumn-Grid {
  max-width: 100%;
  display: grid;
  grid-column-gap: 26px;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.Layout--ThreeColumnMiddleContent-Grid {
  max-width: 100%;
  display: grid;
  grid-column-gap: 26px;
  grid-template-columns: minmax(0, 765px);
  justify-content: center;
  @include media-breakpoint-up(xl) {
    grid-template-columns: minmax(297.5px, 1fr) minmax(0, 765px) minmax(297.5px, 1fr);
  }
}

.Layout--TwoToOneColumns-Grid {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-column-gap: 26px;
    grid-template-columns: 1fr 1fr 1fr;
    > *:first-child {
      grid-column: span 2;
    }
  }
}

.Layout--TwoColumn-Grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 26px;
  }
}

.Layout--OneColumnVertical-Grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 26px;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr;
  }
}