.CheckoutPage--inputs {
  input {
    @include themed() {
      border: 1px solid theme($border-dark);
    }
  }

  @include media-breakpoint-up(md) {
    columns: 2;
  }
}

.CheckoutPage--title {
  font-size: $font-size-6;
}

.Checkout--notify {
  @include themed() {
    color: theme($text-dark);
    background-color: theme($background-light-accent);
  }
  @include font-6;

  span {
    font-weight: $font-weight-bold;
  }
}

.Order-header, .Order-item {
  display: grid;
  grid-gap: 1.25rem;
  align-items: center;
  grid-template-columns: 4fr 1fr;
}

.Order-header p {
  font-weight: $font-weight-normal;
  font-family: $font-family-secondary;
  font-size: $font-size-3;
}