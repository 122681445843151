.PageContainer {
  nav {
    position: relative;
  }

  @include themed() {
    background-color: theme($background-light);
  }
}

.PageContainer-attention {
  color: $white;
  font-weight: $font-weight-normal;
  line-height: 22px;
  @include themed() {
    background-color: theme($background-dark-accent);
  }

  a {
    text-decoration: none;
    @include themed() {
      color: theme($primary);
    }

    &:hover {
      @include themed() {
        color: theme($primary-hover);
      }
    }
  }

  svg {
    width: 14px;

    path {
      fill: $white;
    }

    &:hover {
      path {
        @include themed() {
          fill: theme($primary);
        }
      }
    }
  }

  .Layout--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}