.TagList {
  .Overlay {
    display: none;
  }
}

.TagList--hidden {
  position: relative;
  max-height: 11.75rem;
  overflow: hidden;

  .Overlay {
    display: inherit;
  }
}