.MiniImagePost--content {
  line-height: $line-height-2;
}

.MiniImagePost--title {
  text-decoration: none;
  @include themed() {
    color: theme($text-dark)
  }
}

.MiniImagePost--ImgWrapper {
  @extend %image_wrapper-def;
  aspect-ratio: 1 / 1;
  @include themed() {
    background-color: theme($background-transparent);
  }
}

.MiniImagePost--Img {
  @extend %image-def;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}