.SubscribePageHead {
  font-weight: $font-weight-normal;
  @include themed() {
    background-color: theme($background-dark);
    color: theme($text-light);
  }

  @include media-breakpoint-up(md) {
    text-align: center;
  }
}

.SubscribePageHead--Caption {
  font-family: $font-family-secondary;
  @include font-2;
  @include themed() {
    color: theme($primary);
  }

  @include media-breakpoint-up(md) {
    @include font-default;
  }
}

.SubscribePageHead--Title {
  @include font-12;
  @include media-breakpoint-up(md) {
    @include font-13;
  }
}

.SubscribePageHead--Description {
  @include font-6;
  font-weight: $font-weight-normal;
  max-width: 48ch;
  @include themed() {
    color: theme($text-light-accent);
  }

  @include media-breakpoint-up(md) {
    margin: 0 auto;
    @include font-5;
  }
}