.CapitalCta {
  @include font-2;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  @include themed() {
    background-color: theme($background-transparent);
  }

  &:hover {
    text-decoration: underline;
  }

  &--white {
    color: $white;

    &:hover {
      color: rgba($white, 0.8);
    }
  }

  &--black {
    color: $black;

    &:hover {
      color: rgba($black, 0.8);
    }
  }

  &--primary {
    @include themed() {
      color: theme($primary);
    }


    &:hover {
      @include themed() {
        color: theme($primary-hover);
      }
    }
  }

  &--secondary {
    @include themed() {
      color: theme($secondary);
    }

    &:hover {
      @include themed() {
        color: theme($secondary-accent-hover);
      }
    }
  }
}
