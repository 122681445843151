.ReactPaginate--container {
  display: flex;
  justify-content: center;

  a {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
    padding-top: 50%;
  }
}

.ReactPaginate--container-item {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  line-height: 0;
  padding: 1.25rem;
}

.ReactPaginate--next {
  background: url("/assets/svg/page-next.svg") no-repeat center center / contain;
  background-size: 6px;

  a {
    top: 0;
  }
}

.ReactPaginate--previous {
  background: url("/assets/svg/page-previous.svg") no-repeat center center / contain;
  background-size: 6px;
}