.BigHeroPost {
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.BigHeroPost--figure {
  @extend %image_wrapper-def;
  position: relative;
  z-index: $z-index-1;
  display: grid;
}

.BigHeroPost--figure-Img {
  @extend %image-def;
  aspect-ratio: 16 / 10;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.BigHeroPost--figcaption {
  padding: 25px 0 12px;
}

.BigHeroPost--figcaption-Title {
  @include font-11;
}

.BigHeroPost--figcaption-TitleLink {
  text-decoration: none;
  @include themed() {
    color: theme($text-light);
  }

  &:hover {
    @include themed() {
      color: theme($text-light-hover);
    }
  }
}