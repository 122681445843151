.Button {
  @extend %button_badge-def;
  font-size: $font-size-4;
  padding: 0.563rem 1.25rem;
  text-decoration: none;
  @include themed() {
    background-color: theme($background-transparent);
  }

  transition: all 0.05s ease-in-out;
}

.Button--light_less {
  @include themed() {
    color: theme($text-light);
    border-color: theme($border-light);
  }

  &:hover {
    @include themed() {
      color: theme($text-light-hover);
      background-color: theme($background-light-hover);
    }
  }
}

.Button--dark_less {
  @include themed() {
    color: theme($secondary);
    border-color: theme($border-dark);
  }

  &:hover {
    @include themed() {
      background-color: theme($background-light-accent);
    }
  }
}

.Button--dark_less-roti {
  @include themed() {
    color: theme($primary);
    border-color: theme($border-dark);
  }

  &:hover {
    @include themed() {
      color: theme($primary-hover);
      background-color: theme($background-light-accent);
    }
  }
}

.Button--primary {
  color: $white;
  @include themed() {
    background-color: theme($primary);
    border-color: theme($primary);
  }

  &:hover {
    @include themed() {
      background-color: theme($primary-hover);
      border-color: theme($primary-hover);
    }
  }
}

.Button--secondary {
  color: $white;
  @include themed() {
    background-color: theme($secondary);
    border-color: theme($secondary);
  }

  &:hover {
    @include themed() {
      background-color: theme($secondary-hover);
      border-color: theme($secondary-hover);
    }
  }
}

.Button--tertiary {
  color: $white;
  @include themed() {
    background-color: theme($tertiary);
    border-color: theme($tertiary);
  }

  &:hover {
    @include themed() {
      background-color: theme($tertiary-hover);
      border-color: theme($tertiary-hover);
    }
  }
}

.Button--special_one {
  color: $white;
  @include themed() {
    background-color: theme($special-one);
    border-color: theme($special-one);
  }

  &:hover {
    @include themed() {
      background-color: theme($special-one-hover);
      border-color: theme($special-one-hover);
    }
  }
}

.Button--special_two {
  color: $white;
  @include themed() {
    background-color: theme($special-two);
    border-color: theme($special-two);
  }

  &:hover {
    @include themed() {
      background-color: theme($special-two-hover);
      border-color: theme($special-two-hover);
    }
  }
}

.Button--primary_less {
  @include themed() {
    color: theme($text-dark);
    border-color: theme($primary);
  }

  &:hover {
    color: $white;
    @include themed() {
      background-color: theme($primary);

      svg path {
        fill: $white;
      }
    }
  }
}

.Button--ghost {
  @include themed() {
    background-color: theme($background-transparent);
  }
  &:hover {
    @include themed() {
      background-color: theme($background-light-accent);
    }
  }
}

.Button--fullwidth {
  display: flex;
  width: 100%;
}

.Button--wide {
  padding-left: 4rem;
  padding-right: 4rem;
}

.Button--thin {
  height: 1.75rem;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: $font-size-2;

  &.Button--wide {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.Button--thick {
  height: 3.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
