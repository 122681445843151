.MiniImageProduct--ImgWrapper {
  @extend %image_wrapper-def;
  aspect-ratio: 3 / 4;
  max-width: 54px;
  @include themed() {
    background-color: theme($background-transparent);
  }

  @include media-breakpoint-up(md) {
    margin: 0 auto;
  }
}

.MiniImageProduct--Img {
  @extend %image-def;
}

.MiniImageProduct--link {
  font-weight: 400;
  @include themed() {
    color: theme($primary);
  }

  &:hover, & > a:hover {
    @include themed() {
      color: theme($primary-hover);
    }
  }
}

.MiniImageProduct--caption {
  font-family: $font-family-secondary;
  @include themed() {
    color: theme($text-dark-accent)
  }
}

.MiniImageProduct--title {
  line-height: $line-height-2;
  @include themed() {
    color: theme($text-dark)
  }
}