.CookiesPopup {
  position: fixed;
  width: 100%;
  z-index: $z-index-5;
  bottom: 0;
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.CookiesPopup--wrapper {
  @extend %medium_width-centered;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 90px;
  align-items: center;

  p {
    @include font-3-tight;

    @include themed() {
      color: theme($text-dark);
    }
  }
}

.CookiesPopup--settings {
  @include themed() {
    color: theme($primary)
  }
  text-decoration: underline;
  cursor: pointer;
}