.Input--text, .Input--email, .Input--number {
  padding: 5px 135px 5px 17px;
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border: none;
  border-radius: 3px;
  font-weight: $font-weight-bold;
  font-family: $font-family-primary;
  @include font-3-tight;

  @include themed() {
    color: theme($secondary);
    background-color: theme($background-light);
  }

  &:active, &:focus {
    @include themed() {
      outline: 1px solid theme($secondary);
    }
  }

  &::placeholder {
    @include themed() {
      color: theme($text-light-accent);
    }
    font-weight: $font-weight-normal;
  }
}

.Input--number {
  padding: 8px 8px 8px 8px;
  height: 50px;
  width: 50px;
  border: 1px solid;
  text-align: center;
  @include themed() {
    border-color: theme($border-dark);
  }

  &::-webkit-inner-spin-button {
    opacity: 1;
  }
}