.CategoryBadge {
  @extend %button_badge-def;
  font-size: $font-size-4;
  min-height: 1.75rem;
  padding: 0.25rem 0.75rem 0.125rem 0.75rem;
  text-decoration: none;

  .CardPost--figure &,
  .CardProduct--figure &,
  .BigHeroPost--figure &,
  .HeroPost--figure & {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: $z-index-2;
  }
}
