.ShareLinksMenu {
  position: absolute;
  border-radius: 3px;
  top: calc(100% + 20px);
  right: -12px;
  z-index: $z-index-3;
  cursor: default;
  @include themed() {
    background-color: theme($background-light)
  }
}

.ShareLinksMenu--Nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;

  .IconLink {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}