.SubscribeToNewsletter--dark {
  @include themed() {
    background-color: theme($background-dark);
  }
}

.SubscribeToNewsletter--content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SubscribeToNewsletter--image-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SubscribeToNewsletter--caption {
  font-family: $font-family-secondary;
  @include font-2;
  opacity: 0.5;
  @include media-breakpoint-up(md) {
    @include font-4-tight;
  }
  @include themed() {
    color: theme($text-light);
  }
}

.SubscribeToNewsletter--hook {
  max-width: 26ch;
  @include font-9-tight;
  @include media-breakpoint-up(md) {
    @include font-10-tight;
  }
  @include themed() {
    color: theme($text-light);
  }

  span {
    @include themed() {
      color: theme($primary);
    }
  }
}
