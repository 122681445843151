.Cart {
  display: flex;
  flex-direction: column;
}

.Cart--checkout {
  align-self: flex-end;
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 50%
  }
  @include media-breakpoint-up(md) {
    width: 25%
  }
}

.Cart--checkoutTotal {
  font-size: $font-size-6;
}

.CartItems {
  .CartItem:nth-child(odd) {
    @include themed() {
      background-color: theme($background-light-accent);
    }
  }
}

.CartItem, .CartItem--header {
  display: grid;
  grid-gap: 1.25rem;
  align-items: center;
  @include media-breakpoint-down(sm) {
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  @include media-breakpoint-between(sm, md) {
    grid-template-columns: 5fr 1fr 1fr 1fr;
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: minmax(120px, 1fr) 5fr 1fr 1fr 1fr;
  }
}

.CartItem {
  min-height: 96px;
}

.CartItem--header {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.CartItem--header p {
  font-weight: $font-weight-normal;
  font-family: $font-family-secondary;
  font-size: $font-size-3;
}

.CartItem--image {
  max-height: 96px;
  display: flex;
  justify-content: center;
  align-self: center;

  img {
    object-fit: contain;
    height: 80px;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.CartItem--product, .CartItem--price, .CartItem--quantity, .CartItem--subtotal {
  p {
    font-size: $font-size-default;
    line-height: $line-height-2;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: space-between;
    grid-gap: 1.25rem;
    p {
      display: flex;
      justify-content: center;
      align-self: center;

      &:nth-child(2) {
        text-align: end;
      }
    }
  }

  a {
    text-decoration: none;
    @include themed() {
      color: theme($primary);
    }

    &:hover {
      @include themed() {
        color: theme($primary-hover);
      }
    }
  }
}