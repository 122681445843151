.MailchimpForm {
  position: relative;
}

.MailchimpForm--field.MailchimpForm--field {
  position: relative;
  z-index: $z-index-1;

  button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.MailchimpForm--field-invalid.MailchimpForm--field-invalid {
  border-radius: 3px;
  @include themed() {
    outline: 1px solid theme($error);
  }

  input::placeholder {
    @include themed() {
      color: theme($error);
    }
  }
}

.MailchimpForm--message.MailchimpForm--message {
  @include themed() {
    background-color: theme($background-dark-accent);
  }
  overflow: hidden;
  transition: top ease-out 0.5s, opacity ease-out 0.5s;
  position: relative;
  top: 0;
  color: $white;
  line-height: $line-height-2;
}

.MailchimpForm--message-hidden {
  line-height: $line-height-2;
  opacity: 0;
  top: -4rem;
}