.HamburgerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  @include themed() {
    background-color: theme($background-light);
  }

  @include media-breakpoint-up(md) {
    @include themed() {
      background-color: theme($background-light-accent);
    }
  }

  .HorizontalMenu--menu-items {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .HorizontalMenu--menu-items-item-link {
    font-size: $font-size-7;
    @include media-breakpoint-up(md) {
      font-size: $font-size-4;
    }
  }

  .HeaderLine--UserLogin {
    font-size: $font-size-6;
  }

  .HeaderLine {
    width: 100%;
  }

  .Divider {
    width: 100%;
  }
}

.HamburgerMenu--SlideOut {
  overflow: auto;
  max-height: 100vh;
  box-sizing: border-box;
  top: 0;
  @include themed() {
    background-color: theme($background-light);
  }
}

.HamburgerMenu--TwoColumn {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}