.HeroPosts {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    width: $base_page_width;
  }
}

.HeroPosts--side-posts {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  @include themed() {
    background-color: rgba(theme($background-dark-accent), 0.2);
  }
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
  @include media-breakpoint-up(md) {
    display: flex;
    grid-gap: 0;
    @include themed() {
      background-color: theme($background-transparent);
    }
  }

  h2 {
    grid-column: span 2;
    @include font-9-loose;
    @include themed() {
      color: theme($text-light);
    }
  }
}

.HeroPosts--action {
  @extend %action_bar-bordered-def;
}
