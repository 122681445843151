.SlideOut {
  position: absolute;
  left: 0;
  z-index: $z-index-3;
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.SlideOut--Style {
  width: 100%;
  @include themed() {
    border-color: theme($background-light-accent);
  }
}