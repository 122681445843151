.PageWideCta {
  @include themed() {
    background-color: theme($background-light-accent);
  }

  & a {
    display: flex;
    align-items: center;
    text-decoration: none;
    @include font-8-tight;
    @include themed() {
      color: theme($secondary);
    }
  }

  svg {
    margin-left: 0.75rem;
    height: 13px;
    width: 13px;
    border: 1.5px solid;
    border-radius: 100%;
    padding: 6px;
    @include themed() {
      border-color: theme($text-light-accent);
    }
  }
}