@use "sass:math";

// Themes setup

/* EXAMPLE USAGE

.Button {
  width: 100%;
  @include themed() {
    background-color: theme($background-light);
  }
}

*/

/* GENERATED CSS

.Button {
  background-color: #fff;
}

.dark .Button {
  background-color: #343a40;
}

.Button {
  width: 100%;
}

*/

$themes: (
  default: (
    white-color: $white,
    black-color: $black,
    primary: $roti,
    primary-hover: $tahiti-gold,
    secondary: $chathams-blue,
    secondary-hover: $elephant,
    secondary-accent-hover: $regent-gray,
    tertiary: $robins-blue,
    tertiary-hover: $robins-bluish,
    special-one: $indigo,
    special-one-hover: $indigo-er,
    special-two: $tahiti-gold,
    special-two-hover: $tahiti-gold,
    background-transparent: transparent,
    background-light: $white,
    background-light-hover: rgba($white, 0.2),
    background-light-accent: $porcelain,
    background-dark: $chathams-blue,
    background-dark-hover: rgba($chathams-blue, 0.2),
    background-dark-accent: $elephant,
    checkbox-light: $heather,
    text-light: $white,
    text-light-hover: rgba($white, 0.8),
    text-light-accent: $heather,
    text-light-accent-hover: $heather,
    text-dark: $elephant,
    text-dark-hover: $smalt-blue,
    text-dark-accent: $smalt-blue,
    text-dark-accent-hover: $chathams-blue,
    border-light: $smalt-blue,
    border-dark: $geyser,
    outline-light: rgba($white, 0.2),
    outline-dark: $geyser,
    error: red,
  ),
  dark: (
    white-color: $black,
    black-color: $white,
    primary: $roti,
    primary-hover: $roti,
    secondary: $heather,
    secondary-hover: $regent-gray,
    secondary-accent-hover: $regent-gray,
    tertiary: $robins-blue,
    tertiary-hover: $robins-bluish,
    special-one: $indigo,
    special-one-hover: $indigo-er,
    special-two: $tahiti-gold,
    special-two-hover: $tahiti-gold,
    background-transparent: transparent,
    background-light: $gray-800,
    background-light-hover: rgba($gray-800, 0.2),
    background-light-accent: $gray-700,
    background-dark: $gray-900,
    background-dark-hover: rgba($gray-900, 0.2),
    background-dark-accent: $gray-800,
    checkbox-light: $heather,
    text-light: $white,
    text-light-hover: rgba($white, 0.8),
    text-light-accent: $heather,
    text-light-accent-hover: $heather,
    text-dark: $white,
    text-dark-hover: $gray-200,
    text-dark-accent: $heather,
    text-dark-accent-hover: $gray-300,
    border-light: $regent-gray,
    border-dark: rgba($white, 0.5),
    outline-light: rgba($white, 0.2),
    outline-dark: rgba($white, 0.5),
    error: red,
  ),
);

// variables that hold keys for easier IDE autocomplete
$white-color: 'white-color';
$black-color: 'black-color';
$primary: 'primary';
$primary-hover: 'primary-hover';
$secondary: 'secondary';
$secondary-hover: 'secondary-hover';
$secondary-accent-hover: 'secondary-accent-hover';
$tertiary: 'tertiary';
$tertiary-hover: 'tertiary-hover';
$special-one: 'special-one';
$special-one-hover: 'special-one-hover';
$special-two: 'special-two';
$special-two-hover: 'special-two-hover';
$background-transparent: 'background-transparent';
$background-light: 'background-light';
$background-light-hover: 'background-light-hover';
$background-light-accent: 'background-light-accent';
$background-dark: 'background-dark';
$background-dark-hover: 'background-dark-hover';
$background-dark-accent: 'background-dark-accent';
$checkbox-light: 'checkbox-light';
$text-light: 'text-light';
$text-light-hover: 'text-light-hover';
$text-light-accent: 'text-light-accent';
$text-light-accent-hover: 'text-light-accent-hover';
$text-dark: 'text-dark';
$text-dark-hover: 'text-dark-hover';
$text-dark-accent: 'text-dark-accent';
$text-dark-accent-hover: 'text-dark-accent-hover';
$border-light: 'border-light';
$border-dark: 'border-dark';
$outline-light: 'outline-light';
$outline-dark: 'outline-dark';
$error: 'error';

// theme-map holds temporarily during scss processing map of all possible key:value combinations
$theme-map: null;

// mixin that, when included, will generate properties with values based on current theme
// theme function bellow is needed to select proper key
@mixin themed() {
  @each $theme, $map in $themes {
    // if default, set class in which this mixin is included, to have selected properties for whole body of HTML
    @if $theme == 'default' {
      & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
      // otherwise create class based on theme name, that behaves the same as default but needs to have said class on top of the document
    @else {
      .#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
}

// function shortcut used to set correct properties based on theme
@function theme($key) {
  @return map-get($theme-map, $key);
}

$font-base: 16;

body {
  box-sizing: border-box;
  font-family: $font-family-primary;
  font-weight: $font-weight-semibold;
  font-size:  #{$font-base}px;
}

$font-size-default: #{math.div(16,$font-base)}rem;
$font-size-1: #{math.div(12,$font-base)}rem;
$font-size-2: #{math.div(13,$font-base)}rem;
$font-size-3: #{math.div(14,$font-base)}rem;
$font-size-4: #{math.div(15,$font-base)}rem;
$font-size-5: #{math.div(17,$font-base)}rem;
$font-size-6: #{math.div(18,$font-base)}rem;
$font-size-7: #{math.div(20,$font-base)}rem;
$font-size-8: #{math.div(23,$font-base)}rem;
$font-size-9: #{math.div(24,$font-base)}rem;
$font-size-10: #{math.div(26,$font-base)}rem;
$font-size-11: #{math.div(27,$font-base)}rem;
$font-size-12: #{math.div(35,$font-base)}rem;
$font-size-13: #{math.div(45,$font-base)}rem;
$font-size-14: #{math.div(90,$font-base)}rem;
$line-height-default: #{math.div(26,$font-base)}rem;
$line-height-1: #{math.div(18,$font-base)}rem;
$line-height-2: #{math.div(20,$font-base)}rem;
$line-height-3: #{math.div(21,$font-base)}rem;
$line-height-4: #{math.div(26,$font-base)}rem;
$line-height-5: #{math.div(28,$font-base)}rem;
$line-height-6: #{math.div(26,$font-base)}rem;
$line-height-7: #{math.div(30,$font-base)}rem;
$line-height-8: #{math.div(30,$font-base)}rem;
$line-height-9: #{math.div(36,$font-base)}rem;
$line-height-10: #{math.div(34,$font-base)}rem;
$line-height-11: #{math.div(30,$font-base)}rem;
$line-height-12: #{math.div(41,$font-base)}rem;
$line-height-13: #{math.div(53,$font-base)}rem;
$line-height-14: #{math.div(105,$font-base)}rem;

$line-height-2-tight: #{math.div(15,$font-base)}rem;
$line-height-3-tight: #{math.div(18,$font-base)}rem;
$line-height-4-tight: #{math.div(20,$font-base)}rem;
$line-height-5-tight: #{math.div(20,$font-base)}rem;
$line-height-6-tight: #{math.div(22,$font-base)}rem;
$line-height-6-loose: #{math.div(34,$font-base)}rem;
$line-height-7-tight: #{math.div(26,$font-base)}rem;
$line-height-8-tight: #{math.div(26,$font-base)}rem;
$line-height-8-loose: #{math.div(34,$font-base)}rem;
$line-height-9-tight: #{math.div(30,$font-base)}rem;
$line-height-9-loose: #{math.div(40,$font-base)}rem;
$line-height-11-loose: #{math.div(40,$font-base)}rem;
$line-height-10-tight: #{math.div(30,$font-base)}rem;
$line-height-12-loose: #{math.div(48,$font-base)}rem;

@mixin font-default {
  font-size: $font-size-default;
  line-height: $line-height-default;
}

@mixin font-1 {
  font-size: $font-size-1;
  line-height: $line-height-1;
}

@mixin font-2 {
  font-size: $font-size-2;
  line-height: $line-height-2;
}

@mixin font-3 {
  font-size: $font-size-3;
  line-height: $line-height-3;
}

@mixin font-4 {
  font-size: $font-size-4;
  line-height: $line-height-4;
}

@mixin font-5 {
  font-size: $font-size-5;
  line-height: $line-height-5;
}

@mixin font-6 {
  font-size: $font-size-6;
  line-height: $line-height-6;
}

@mixin font-7 {
  font-size: $font-size-7;
  line-height: $line-height-7;
}

@mixin font-8 {
  font-size: $font-size-8;
  line-height: $line-height-8;
}

@mixin font-9 {
  font-size: $font-size-9;
  line-height: $line-height-9;
}

@mixin font-10 {
  font-size: $font-size-10;
  line-height: $line-height-10;
}

@mixin font-11 {
  font-size: $font-size-11;
  line-height: $line-height-11;
}

@mixin font-12 {
  font-size: $font-size-12;
  line-height: $line-height-12;
}

@mixin font-13 {
  font-size: $font-size-13;
  line-height: $line-height-13;
}

@mixin font-14 {
  font-size: $font-size-14;
  line-height: $line-height-14;
}

@mixin font-2-tight {
  font-size: $font-size-2;
  line-height: $line-height-2-tight;
}

@mixin font-3-tight {
  font-size: $font-size-3;
  line-height: $line-height-3-tight;
}

@mixin font-4-tight {
  font-size: $font-size-4;
  line-height: $line-height-4-tight;
}

@mixin font-5-tight {
  font-size: $font-size-5;
  line-height: $line-height-5-tight;
}

@mixin font-6-tight {
  font-size: $font-size-6;
  line-height: $line-height-6-tight;
}

@mixin font-6-loose {
  font-size: $font-size-6;
  line-height: $line-height-6-loose;
}

@mixin font-7-tight {
  font-size: $font-size-7;
  line-height: $line-height-7-tight;
}

@mixin font-8-tight {
  font-size: $font-size-8;
  line-height: $line-height-8-tight;
}

@mixin font-8-loose {
  font-size: $font-size-8;
  line-height: $line-height-8-loose;
}

@mixin font-9-tight {
  font-size: $font-size-9;
  line-height: $line-height-9-tight;
}

@mixin font-9-loose {
  font-size: $font-size-9;
  line-height: $line-height-9-loose;
}

@mixin font-10-tight {
  font-size: $font-size-10;
  line-height: $line-height-10-tight;
}

@mixin font-11-loose {
  font-size: $font-size-11;
  line-height: $line-height-11-loose;
}

@mixin font-12-loose {
  font-size: $font-size-12;
  line-height: $line-height-12-loose;
}
