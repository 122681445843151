.SearchPage--error {
  @include themed() {
    color: theme($text-dark);
    background-color: theme($background-light-accent);
  }
  @include font-6;

  span {
    font-weight: $font-weight-bold;
  }
}