.CardProduct {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardProduct--figure {
  position: relative;
  z-index: $z-index-1;
  .CategoryBadge.Button--primary:hover {
    cursor: default;
    @include themed() {
      background-color: theme($primary);
      border-color: theme($primary);
    }
  }
}

.CardProduct--figure-ImgWrapper {
  @extend %image_wrapper-def;
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.CardProduct--figure-Img {
  @extend %image-def;
  aspect-ratio: 16 / 9;
  object-fit: scale-down;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.CardProduct--figcaption-Title {
  @include font-6-tight;
  margin: 8px 0 16px 0;
}

.CardProduct--figcaption-TitleLink {
  text-decoration: none;
  @include themed() {
    color: theme($text-dark);
  }

  &:hover {
    @include themed() {
      color: theme($text-dark-hover);
    }
  }
}

.CardProduct--action {
  @extend %action_bar-def;

  button {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}

.CardProduct--action-price {
  font-family: $font-family-secondary;
  @include font-3;
  @include themed() {
    color: theme($text-dark-accent);
  }
}