.SearchForm {
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: $z-index-1;

  @include media-breakpoint-up(md) {
    max-width: 500px;
  }

  input {
    border: 1px solid;
    @include themed() {
      background-color: theme($background-light-accent);
      border-color: theme($border-dark);
    }
    @include media-breakpoint-up(md) {
      @include themed() {
        background-color: theme($background-light);
      }
      border: none;
    }
  }

  button.Button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  button.IconLink {
    border: none;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      height: 16px;
      width: 16px;
      border-right: 1px solid;
      padding: 0.25rem 0.5rem 0.25rem 0.5rem;
      @include themed() {
        border-color: theme($border-dark);
      }
    }

    + input {
      padding-left: 55px;
    }
  }
}