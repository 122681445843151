.ChooseNewsletterForm--Title {
  @include font-6-loose;
  @include themed() {
    color: theme($secondary);
  }

  @include media-breakpoint-up(md) {
    text-align: center;
    @include font-9;
  }
}

.ChooseNewsletterForm--Input {
  display: flex;
  justify-content: center;

  > div {
    max-width: 765px;
    width: 100%;
    position: relative;
  }

  input {
    border: 1px solid;
    @include themed() {
      background-color: theme($background-light-accent);
      border-color: theme($border-dark);
    }
  }

  button.Button {
    @include media-breakpoint-up(md) {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.ChooseNewsletterForm--Checkbox {
  display: flex;
  justify-content: center;
}