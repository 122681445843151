.PostPageBanner {
  @include themed() {
    background-color: theme($background-dark);
  }

  .Author {
    @include themed() {
      color: theme($text-light-accent);
    }

    span {
      @include themed() {
        color: theme($primary);
      }

      &:hover {
        @include themed() {
          color: theme($primary-hover);
          text-decoration: underline;
        }
      }
    }
  }

  .DateAndAuthor-date {
    @include themed() {
      color: theme($text-light-accent);
    }
  }

  .EstimatedReadTime--time {
    span {
      @include themed() {
        color: theme($text-light);
      }
    }
  }
}

.PostPageBanner--partner {
  @include themed() {
    background-color: theme($background-light-accent);
  }

  .Layout--left {
    display: flex;
    align-items: center;
  }
}

.PostPageBanner--partner-image {
  max-width: 100%;
  max-height: 1.75rem;
}

.PostPageBanner--partner-share {
  display: flex;
  align-items: center;
  justify-content: end;

  p {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: $font-weight-normal;
    font-family: $font-family-secondary;
    @include font-3-tight;
    @include themed() {
      color: theme($text-dark);
    }
  }

  > div {
    position: relative;
    display: flex;
    align-items: center;
  }
}

.PostPageBanner--wrapper {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  @include media-breakpoint-up(md) {
    max-width: 820px;
  }
}

.PostPageBanner--ImgWrapper {
  margin: 0 auto;
  max-width: 900px;
  aspect-ratio: 16 / 9;
  @extend %image_wrapper-def;
}

.PostPageBanner--Img {
  @extend %image-def;
}

.PostPageBanner--caption {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.PostPageBanner--title {
  font-weight: $font-weight-semibold;
  text-align: center;
  @include font-12;
  @include themed() {
    color: theme($text-light);
  }
  @include media-breakpoint-up(md) {
    @include font-13;
  }
}

.PostPageBanner--action {
  @extend %action_bar-bordered-def;
}

.PostPageBanner--tags {
  text-align: center;
}

.GradBackground {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;

  &::before {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $z-index-0;
    @include themed() {
      background-color: rgba(theme($background-dark), 0.75);
      background-image: linear-gradient(to bottom, rgba(theme($background-dark), 0) 0%, rgba(theme($background-dark), 1) 100%);
    }
  }
}
