.SectionHeader {
  display: flex;
  justify-content: space-between;
  @include themed() {
    border-color: theme($secondary)
  }

  & > h2 {
    font-weight: $font-weight-bold;
    @include font-7;
    @include themed() {
      color: theme($secondary)
    }

    span {
      @include themed() {
        color: theme($primary)
      }
    }

    @include media-breakpoint-up(md) {
      font-size: $font-size-9;
    }
  }

  &--BorderThick {
    border-top: 3px solid;
  }

  &--BorderNone {
    border-top: none;
  }
}

.SectionHeader--right {
  display: flex;
  align-items: center;
  a {
    text-transform: uppercase;
    text-decoration: none;
    @include font-2;
    @include themed() {
      color: theme($secondary)
    }
  }
}
