.PostDetailAuthor {
  @include themed() {
    background-color: theme($background-light-accent);
  }
}

.PostDetailAuthor--NameWrapper {
  display: flex;
  justify-content: space-between;
}

.PostDetailAuthor--Description {
  font-weight: $font-weight-normal;
  @include font-4;
  @include themed() {
    color: theme($text-dark-accent);
  }
}
