.PostContent {
  @extend %wordpress-content;

  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  .has-large-font-size {
    @extend %thin_width-centered;
  }

  .wp-block-quote,
  .wp-block-pullquote,
  .wp-block-image,
  .wp-block-media-text__media,
  .wp-block-gallery,
  .wp-block-embed,
  .wp-block-jetpack-mailchimp,
  .fatchilliRectangleSilver,
  .fatchilliRectangleYellow {
    @extend %medium_width-centered;
  }

  .wp-block-image,
  .wp-block-media-text__media {
    &.size-large {
      max-width: inherit;

      img {
        max-width: 100%;
        min-width: 100%;
      }

      @include media-breakpoint-up(xl) {
        margin-left: -200px;
        margin-right: -200px;
        figcaption {
          margin-left: 245px;
        }
      }
    }
  }

  .MailchimpForm--message {
    margin-bottom: 1.5rem;
    padding: 1.25rem;
    font-weight: $font-weight-semibold;
    font-size: $font-size-default;
  }
}