.AllTopicsPage {
  max-width: 68ch;
  margin: 0 auto;

  .TagList {
    p {
      display: inline;
    }

    @include media-breakpoint-up(md) {
      .TagList-tags {
        columns: 2;
      }

      p {
        display: block;
      }
    }
  }
}

.AllTopicsPage-searchForm {
  position: relative;

  input {
    border: 1px solid;
    @include themed() {
      background-color: theme($background-light-accent);
      border-color: theme($border-dark);
    }
  }

  button.Button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}