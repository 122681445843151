.MiniPostWrapper {
  border-top: 1px dashed;
  display: grid;
  grid-template-columns: 72px 1fr;
  @include themed() {
    border-color: theme($border-dark);
  }

  &:last-child {
    border-bottom: 1px dashed;
    @include themed() {
      border-color: theme($border-dark);
    }
  }
}
