.ProductPageBanner {
  @include themed() {
    background-color: theme($background-dark);
  }
}

.ProductPageBanner--Wrapper {
  align-items: center;
}

.ProductPageBanner--Headlines {
  min-width: 100%;

  .CapitalCta {
    text-decoration: underline;
  }
}

.ProductPageBanner--Caption {
  @include font-2;
  font-family: $font-family-secondary;
  @include themed() {
    color: theme($primary);
  }

  @include media-breakpoint-up(md) {
    @include font-default;
  }
}

.ProductPageBanner--Title {
  @include font-12;
  @include themed() {
    color: theme($text-light);
  }

  @include media-breakpoint-up(md) {
    @include font-13;
  }
}

.ProductPageBanner--Description {
  @include font-6;
  font-weight: $font-weight-normal;
  @include themed() {
    color: theme($text-light-accent);
  }

  @include media-breakpoint-up(md) {
    @include font-8;
  }
}

.ProductPageBanner--Price {
  display: flex;
  border-top: 1px solid;
  border-bottom: 1px solid;
  font-weight: $font-weight-normal;
  max-width: 100%;
  @include themed() {
    color: theme($text-light-accent);
    border-color: theme($border-light);
  }
}

.ProductPageBanner--Price-Members {
  border-right: 1px solid;
  @include themed() {
    border-color: theme($border-light);
  }
}

.ProductPageBanner--Price-Caption {
  @include font-3;
}

.ProductPageBanner--Price-Value {
  @include font-9-tight;
  font-family: $font-family-secondary;
  display: inline-flex;

  &Discount {
    @extend .ProductPageBanner--Price-Value;
    display: flex;
    align-items: center;
  }
}