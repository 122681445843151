.Checkbox {
  margin: 0;

  &:checked {
    & + label {
      svg {
        display: block;
      }
    }
  }
}

.Checkbox--label {
  padding: 0 0 0 33px;
  position: relative;
  z-index: $z-index-1;
  font-weight: $font-weight-normal;
  display: inline-block;
  @include font-3-tight;

  a {
    text-decoration: underline;
    color: inherit;
  }

  &::before {
    content: '';
    width: 22px;
    height: 22px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 3px;
    @include themed() {
      background-color: theme($checkbox-light);
    }
  }

  svg {
    width: 9px;
    height: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6.5px;
    display: none;
  }
}

.Checkbox--Box {
  &-Primary {
    &::before {
      @include themed() {
        background-color: theme($primary);
      }
    }
  }

  &-Secondary {
    &::before {
      @include themed() {
        background-color: theme($secondary);
      }
    }
  }

  &-Light {
    &::before {
      @include themed() {
        background-color: theme($checkbox-light);
      }
    }
  }
}

.Checkbox--Check {
  &-Primary {
    path {
      @include themed() {
        fill: theme($primary);
      }
    }
  }

  &-Secondary {
    path {
      @include themed() {
        fill: theme($secondary);
      }
    }
  }

  &-White {
    path {
      fill: $white;
    }
  }
}

.Checkbox--Text {
  &-Primary {
    @include themed() {
      color: theme($primary);
    }
  }

  &-Secondary {
    @include themed() {
      color: theme($secondary);
    }
  }

  &-White {
    @include themed() {
      color: theme($text-light);
    }
  }
}