.LanguageToggle {
  display: inline-flex;
  align-items: center;
  font-family: $font-family-secondary;
  cursor: pointer;
  border: none;
  @include font-3-tight;

  @include themed() {
    color: theme($text-dark);
    background-color: theme($background-transparent);
  }

  &:hover {
    @include themed() {
      color: theme($text-dark-hover);
    }
  }

  /*
  @include media-breakpoint-up(md) {
    border-left: 1px solid;
    @include themed() {
      border-color: theme($border-dark);
    }
  }
  */

  svg {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
}