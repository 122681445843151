.HorizontalMenu {
  & .Layout__left {
    flex-grow: 1;
    flex-basis: 0;
  }

  & .Layout__right {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: end;
  }
}

.HorizontalMenu--menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.HorizontalMenu--menu-items-item {
  display: inline;
}

.HorizontalMenu--menu-items-item-link {
  font-weight: $font-weight-normal;
  @include font-4-tight;
  text-decoration: none;
  text-transform: capitalize;
  @include themed() {
    color: theme($secondary);
  }

  &:hover {
    @include themed() {
      color: theme($secondary-accent-hover);
    }
  }
}

.HorizontalMenu--search  {
  svg {
    width: 16px;
    height: 16px;
  }
}

.HorizontalMenu--Wrapper {
  position: relative;
}

.HorizontalMenu--Sticky {
  transition: top ease-out 0.5s;
  position: fixed;
  width: 100%;
  z-index: $z-index-3;
  top: 0;
  @include themed() {
    background-color: theme($background-light);
  }
}

.HorizontalMenu--Sticky-hidden {
  top: -5rem;
}