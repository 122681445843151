.Backdrop {
  position: fixed;
  top: 0;
  z-index: $z-index-2;
  opacity: 0.8;
  width: 100%;
  height: 100%;
}

.Backdrop-primary {
  @include themed() {
    background-color: theme($primary);
  }
}

.Backdrop-secondary {
  @include themed() {
    background-color: theme($secondary);
  }
}

.Backdrop-white {
  @include themed() {
    background-color: theme($white);
  }
}

.Backdrop-black {
  @include themed() {
    background-color: theme($black);
  }
}